import { ReactComponent as DailyMail } from 'assets/logos/daily-mail.svg'
import { ReactComponent as Forbes } from 'assets/logos/forbes.svg'
import { ReactComponent as Hello } from 'assets/logos/hello.svg'
import { ReactComponent as Mashable } from 'assets/logos/mashable.svg'
import { ReactComponent as TheSun } from 'assets/logos/the-sun.svg'
import { ReactComponent as Yahoo } from 'assets/logos/yahoo.svg'
import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const Logos: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(className, styles.logosWrapper)} {...props}>
      <h4 className={styles.title}>{t('logos-title')}</h4>
      <div className={styles.logos}>
        <Yahoo />
        <Forbes />
        <Mashable />
      </div>
      <div className={styles.logos}>
        <Hello />
        <DailyMail />
        <TheSun />
      </div>
    </div>
  )
}

export default Logos
