import { ReactComponent as Acquaintance } from 'assets/svg/compatibility/acquaintance.svg'
import { ReactComponent as Boss } from 'assets/svg/compatibility/boss.svg'
import { ReactComponent as Colleague } from 'assets/svg/compatibility/colleague.svg'
import { ReactComponent as Crush } from 'assets/svg/compatibility/crush.svg'
import { ReactComponent as Friend } from 'assets/svg/compatibility/friend.svg'
import { ReactComponent as Partner } from 'assets/svg/compatibility/partner.svg'
import { ReactComponent as Relative } from 'assets/svg/compatibility/relative.svg'
import { useDispatch, useSelect } from 'store/hook'
import { VariantOptions } from 'types'
import { analyticEvent } from 'utils/analytics'
import useNextRoute from 'utils/hooks/useNextRoute'

import ButtonIcon from 'components/compatibility/button-icon'
import ProgressBar from 'components/compatibility/progress-bar'
import ContentWrapper from 'components/content-wrapper'

import styles from './styles.module.scss'

const variants = {
  love: [
    { title: 'crush', icon: <Crush /> },
    { title: 'partner', icon: <Partner /> },
  ],
  friendship: [
    { title: 'friend', icon: <Friend /> },
    { title: 'relative', icon: <Relative /> },
    { title: 'acquaintance', icon: <Acquaintance /> },
  ],
  work: [
    { title: 'colleague', icon: <Colleague /> },
    { title: 'boss', icon: <Boss /> },
  ],
}

export const TargetVariant = () => {
  const { target, variant } = useSelect()
  const dispatch = useDispatch()
  const nextRoute = useNextRoute()

  const onClick = (payload: VariantOptions) => {
    dispatch({ type: 'changeVariant', payload })
    analyticEvent({ name: 'CompatibilityOnboardingCompatibility2Clicked', gtagData: payload })

    nextRoute()
  }

  return (
    <ContentWrapper>
      <ProgressBar />
      <h3 className={styles.title}>Who would you like to check now?</h3>
      <div className={styles.buttonWrapper}>
        {target &&
          variants[target].map((item, index) => (
            <ButtonIcon
              onClick={() => onClick(item.title as VariantOptions)}
              className={styles.customButton}
              title={item.title}
              icon={item.icon}
              key={index}
              isActive={item.title === variant}
            />
          ))}
      </div>
    </ContentWrapper>
  )
}
export default TargetVariant
