import { ReactComponent as Shield } from 'assets/svg/shield.svg'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const BiometricData = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.biometricData}>
      <Shield /> {t('biometric-data')}
    </div>
  )
}

export default BiometricData
