import { Trans } from 'react-i18next'
import { useDispatch, useSelect } from 'store/hook'
import SingleQuestionPage from 'templates-page/single-question-page'
import { WishOptions } from 'types'
import { analyticEvent } from 'utils/analytics'

const options = {
  love: 'Love & Relationship',
  health: 'Health & Vitality',
  career: 'Career & Destiny',
}

export const Wish = () => {
  const dispatch = useDispatch()
  const { wish } = useSelect()

  const onSaveOption = (key: WishOptions) => {
    dispatch({
      type: 'changeWish',
      payload: key,
    })
    analyticEvent({ name: 'PalmistryOnboardingWishClicked', gtagData: key })
  }

  return (
    <SingleQuestionPage
      onSaveOption={onSaveOption}
      options={options}
      title={<Trans i18nKey="relationship-status-title" />}
      activeOption={wish}
    />
  )
}
