import clsx from 'clsx'
import { FC, useState } from 'react'

import NextButton from 'components/compatibility/next-button'
import ProgressBar from 'components/compatibility/progress-bar'
import ContentWrapper from 'components/content-wrapper'
import GeoPlaceSelector from 'components/geo-place-selector'

import styles from './styles.module.scss'

type Props = {
  to?: string
  title: string
  description: string
  place: string
  pages?: number
  currentPage?: number
  isBackHistory?: boolean
  onSelectPlace: (place: string) => void
  onClear: () => void
  onNextPage?: () => void
}

const ERROR_MESSAGE = 'Please enter your place of birth in format: Springfield, Massachusetts, USA'

const BornPlace: FC<Props> = ({
  to,
  title,
  description,
  place,
  pages,
  currentPage,
  isBackHistory,
  onSelectPlace,
  onClear,
  onNextPage,
}) => {
  const [isError, setError] = useState(false)

  const isDisableNextButton = isError || !place

  return (
    <ContentWrapper>
      <ProgressBar isBackHistory={isBackHistory} pages={pages} currentPage={currentPage} />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      <GeoPlaceSelector
        place={place}
        placeholder="City, State, Country"
        onClear={onClear}
        onSelectPlace={onSelectPlace}
        classNameInput={clsx(styles.customInputSelector, isError && styles.inputError)}
        classNameList={styles.customList}
        setError={setError}
        textError={isError ? ERROR_MESSAGE : undefined}
      />
      <NextButton onClick={onNextPage} to={to} disabled={isDisableNextButton} />
    </ContentWrapper>
  )
}
export default BornPlace
