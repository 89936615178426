import { ReactComponent as Calendar } from 'assets/svg/funny-calendar.svg'
import { ReactComponent as Star } from 'assets/svg/funny-star.svg'
import { ReactComponent as Lock } from 'assets/svg/lock.svg'
import clsx from 'clsx'
import { FC, useState } from 'react'
import useNextRoute from 'utils/hooks/useNextRoute'
import usePaymentAddon from 'utils/hooks/usePaymentAddon'

import Button from 'components/button'
import Spinner from 'components/circle-spinner'
import ContentWrapper from 'components/content-wrapper'
import EmailHeader from 'components/email-header'

import Error from './error'
import Modal from './modal'
import styles from './styles.module.scss'

const PremiumBundle: FC = () => {
  const nextRoute = useNextRoute()
  const paymentAddon = usePaymentAddon()

  const [isShowModal, setShowModal] = useState(false)
  const [isShowError, setShowError] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const onBuyTextBook = async () => {
    setIsLoading(true)
    await paymentAddon('gude_addon')
      .then(() => {
        setShowModal(true)
      })
      .catch(() => {
        setShowError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ContentWrapper className={styles.premiumBundleWrapper}>
      <Modal open={isShowModal} />
      <EmailHeader />
      <button onClick={() => nextRoute()} disabled={isLoading} className={styles.buttonSkip}>
        {'Skip >'}
      </button>
      <h1 className={styles.title}>Get extra insights with our Premium Bundle</h1>
      <h4 className={styles.subTitle}>
        Exclusive offer: recommended for get more insights about what future holds for you.
      </h4>
      <div className={clsx(styles.errorWrapper, isShowError && styles.showError)}>
        <Error />
      </div>
      <div className={styles.blockDescription}>
        <h1>What your Premium Bundle will include:</h1>
        <div className={styles.item}>
          <div className={styles.iconWrapper}>
            <Star />
          </div>
          <p>Guide to Modern Astrology</p>
        </div>
        <div className={styles.item}>
          <div className={styles.iconWrapper}>
            <Calendar />
          </div>
          <p>Annual Forecast for 2023</p>
        </div>
        <section>
          <h2>One-time price of $19!</h2>
          <p>Original price is $45. Save 58%!</p>
        </section>
        <section>
          <p>
            These plans are <b>yours to keep</b> even if you decide Hint isn’t right for you.
          </p>
        </section>
      </div>

      <Button disabled={isLoading} onClick={onBuyTextBook} isActive className={styles.buyButton}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Lock /> Buy now
          </>
        )}
      </Button>
    </ContentWrapper>
  )
}
export default PremiumBundle
