import clsx from 'clsx'
import { FC, ReactNode, SyntheticEvent, useEffect } from 'react'

import styles from './styles.module.scss'

export type ModalProps = {
  children: ReactNode
  open: boolean
  className?: string
  onClose?: () => void
}

const Modal: FC<ModalProps> = ({ children, open, className, onClose }) => {
  useEffect(() => {
    const { body } = document
    if (open) {
      body.style.overflow = 'hidden'
    }

    return () => {
      body.style.overflow = 'initial'
    }
  }, [open])

  if (!open) {
    return null
  }

  const onHandleClose = (event: SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    document.body.style.overflow = ''
    if (onClose) {
      onClose()
    }
  }

  const onClickModal = (event: SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div className={styles.modalWrapper} onClick={onHandleClose}>
      <div onClick={onClickModal} className={clsx(styles.modal, className)}>
        {children}
      </div>
    </div>
  )
}
export default Modal
