import { ReactComponent as Flower } from 'assets/svg/flower.svg'
import { ReactComponent as Plaster } from 'assets/svg/plaster.svg'
import { ReactComponent as Pulse } from 'assets/svg/pulse.svg'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import i18n from 'translations/i18n'

import Description from './description'
import DiscountTimer from './discount-timer'
import PaymentSection from './payment-section'
import styles from './styles.module.scss'
import Subscription from './subscription'

const blocksHealth = [
  { icon: <Flower />, text: i18n.t('blocks-health-stress') },
  { icon: <Pulse />, text: i18n.t('blocks-health-prevent') },
  { icon: <Plaster />, text: i18n.t('blocks-health-accidents') },
]

const Payment: FC = () => {
  const { t } = i18n
  return (
    <section className={styles.paymentSection}>
      <div className={styles.wrapperTimer}>
        <DiscountTimer />
      </div>
      <Subscription description={t('palm-subscription-description')} price={13.21} />
      <Description />
      <div className={styles.icons}>
        {blocksHealth.map((item, index) => (
          <div key={index} className={styles.itemIcon}>
            <div className={styles.wrapperIcon}>{item.icon}</div>
            <h3 className={styles.textIcon}>{item.text}</h3>
          </div>
        ))}
      </div>
      <PaymentSection />
      <div className={styles.footnote}>
        <Trans i18nKey="payment-footnote" />
      </div>
    </section>
  )
}

export default Payment
