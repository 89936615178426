import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

type Props = {
  children: ReactNode
  className?: string
}

const PageWrapper: FC<Props> = ({ children, className }) => {
  return <div className={clsx(styles.wrapper, className)}>{children}</div>
}

export default PageWrapper
