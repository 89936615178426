import { ReactComponent as Logo } from 'assets/svg/logo.svg'
import { paths } from 'onboardings/palmistry-onboarding/paths'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import useCurrentRouteIndex from 'utils/hooks/useCurrentRouteIndex'

import ButtonBack from '../button-back'
import styles from './styles.module.scss'

const Header: FC = () => {
  const location = useLocation()

  const isShowEmail = location.pathname === paths.palmPaywall || location.pathname === paths.palmDownloadPage

  const currentRouteIndex = useCurrentRouteIndex()
  return (
    <header className={styles.header}>
      {!isShowEmail && !!currentRouteIndex && <ButtonBack className={styles.buttonPosition} />}
      <Logo />
    </header>
  )
}

export default Header
