import { FC, useEffect, useState } from 'react'

import styles from './styles.module.scss'

const TIME_DURATION_MINUTES = 15
const TIME_DURATION_SECONDS = 59

const DiscountTimer: FC = () => {
  const [seconds, setSeconds] = useState(TIME_DURATION_SECONDS)
  const [minutes, setMinutes] = useState(TIME_DURATION_MINUTES)
  const [timerEnd, setTimerEnd] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((previous) => --previous)

      if (!timerEnd && seconds <= 0) {
        setSeconds(TIME_DURATION_SECONDS)
        setMinutes((previous) => --previous)
      }

      if (seconds <= 0 && minutes <= 0) {
        setMinutes(TIME_DURATION_MINUTES)
        setSeconds(TIME_DURATION_SECONDS)
        setTimerEnd(true)
        clearInterval(interval)
      }
      setTimerEnd(false)
    }, 1000)
    return () => clearInterval(interval)
  }, [seconds, minutes, timerEnd])

  return <p className={styles.timer}>{`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}</p>
}

export default DiscountTimer
