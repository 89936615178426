import { ReactComponent as Palm } from 'assets/svg/palm.svg'
import { FC, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DescriptionImagePage from 'templates-page/description-image-page'
import { analyticEvent } from 'utils/analytics'

export const PalmWelcome: FC = () => {
  const { t } = useTranslation()

  useEffect(() => {
    analyticEvent({ name: 'PalmistryOnboardingStarted' })
  }, [])

  const onNextPage = () => {
    analyticEvent({ name: 'PalmistryOnboardingIntroClicked' })
  }

  return (
    <DescriptionImagePage
      image={<Palm />}
      description={
        <h2>
          <Trans i18nKey="palm-welcome-text" />
        </h2>
      }
      textButton={t('palm-welcome-button-next')}
      onNextPage={onNextPage}
    />
  )
}
