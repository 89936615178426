import { Trans } from 'react-i18next'
import { useDispatch, useSelect } from 'store/hook'
import SingleQuestionPage from 'templates-page/single-question-page'
import i18n from 'translations/i18n'
import { IsBornTimeOptions } from 'types'
import { analyticEvent } from 'utils/analytics'

const options = {
  yes: i18n.t('yes'),
  no: i18n.t('no'),
}

export const BornTimeQuestion = () => {
  const dispatch = useDispatch()
  const { isBornTime } = useSelect()

  const activeOption = () => {
    if (isBornTime === null) {
      return null
    }
    return isBornTime ? 'yes' : 'no'
  }

  const onSaveOption = (key: IsBornTimeOptions) => {
    dispatch({
      type: 'changeIsBornTime',
      payload: key === 'yes',
    })
    analyticEvent({ name: 'PalmistryOnboardingTOBQClicked', gtagData: key })
  }

  return (
    <SingleQuestionPage
      onSaveOption={onSaveOption}
      options={options}
      title={<Trans i18nKey="born-time-question-title" />}
      activeOption={activeOption()}
    />
  )
}
