import { ReactComponent as Alert } from 'assets/svg/alert.svg'

import styles from './styles.module.scss'

const Error = () => {
  return (
    <div className={styles.error}>
      <div className={styles.icon}>
        <Alert />
      </div>
      <p>
        There was an error processing your payment information. Please check your payment information and try again or
        try a new payment method.
      </p>
    </div>
  )
}

export default Error
