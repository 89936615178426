import screenAppPreview from 'assets/images/preview/screen-app.png'
import screenApp from 'assets/images/screen-app.png'
import { useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { analyticEvent } from 'utils/analytics'

import ButtonDownloadApp from 'components/button-download-app'
import ContentWrapper from 'components/content-wrapper'
import EmailHeader from 'components/email-header'

import styles from './styles.module.scss'

export const Download = () => {
  useEffect(() => {
    analyticEvent({
      name: 'CompatibilityOnboardingSuccessScreenShown',
    })
  }, [])

  return (
    <ContentWrapper>
      <EmailHeader className={styles.header} />
      <h2 className={styles.title}>Simply follow these steps to access your account</h2>
      <div className={styles.item}>
        <p>1.</p>
        <p>Download Hint app by clicking on the blue button below.</p>
      </div>
      <div className={styles.item}>
        <p>2.</p>
        <p>Launch the Hint app on your phone and tap “Log with Email” (see the image below).</p>
      </div>
      <div className={styles.imageWrapper}>
        <LazyLoadImage
          src={screenApp}
          width={239}
          height={168}
          alt="page's screenshot signup on app"
          placeholderSrc={screenAppPreview}
        />
      </div>
      <div className={styles.item}>
        <p>3.</p>
        <p>Enter your email and follow the instructions to create your password.</p>
      </div>

      <ButtonDownloadApp />
    </ContentWrapper>
  )
}
export default Download
