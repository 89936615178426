import { useSelect } from 'store/hook'

const useUserData = () => {
  const { emailUser, gtagClientId, onboarding } = useSelect()

  return {
    email: emailUser,
    advertisingID: gtagClientId || '',
    gtagClientID: gtagClientId || '',
    appName: onboarding,
    planCode: '1_week_19_13',
  }
}
export default useUserData
