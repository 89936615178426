import { ReactComponent as PalmScan } from 'assets/svg/palm-scan.svg'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DescriptionImagePage from 'templates-page/description-image-page'
import { analyticEvent } from 'utils/analytics'

import BiometricData from 'components/biometric-data'

import styles from './styles.module.scss'

export const PalmScanInfo: FC = () => {
  const { t } = useTranslation()

  const title = <h2 className={styles.title}>{t('palm-scan-info-title')}</h2>

  const onNextPage = () => {
    analyticEvent({ name: 'PalmistryOnboardingScanIntroClicked' })
  }

  return (
    <DescriptionImagePage
      image={<PalmScan />}
      title={title}
      descriptionSmallText={<Trans i18nKey="palm-scan-info-text" />}
      textButton={t('palm-scan-info-button-next')}
      isShowPolicy={false}
      footer={<BiometricData />}
      onNextPage={onNextPage}
    />
  )
}
