import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelect } from 'store/hook'
import SingleQuestionPage from 'templates-page/single-question-page'
import i18n from 'translations/i18n'
import { GenderOptions } from 'types'
import { analyticEvent } from 'utils/analytics'

const options = {
  male: i18n.t('male'),
  female: i18n.t('female'),
}

export const Gender = () => {
  const dispatch = useDispatch()
  const { gender } = useSelect()
  const { t } = useTranslation()

  const text = (
    <h4>
      <Trans i18nKey="gender-text" />
    </h4>
  )

  const onSaveOption = (key: GenderOptions) => {
    dispatch({
      type: 'changeGender',
      payload: key,
    })
    analyticEvent({ name: 'PalmistryOnboardingGenderClicked', gtagData: key })
  }

  return (
    <SingleQuestionPage
      activeOption={gender}
      onSaveOption={onSaveOption}
      options={options}
      title={t('gender-title')}
      text={text}
    />
  )
}
