import { Reducer } from 'react'

import { Actions, InitState } from './types'

const reducer: Reducer<InitState, Actions> = (state, action) => {
  switch (action.type) {
    case 'changeOnboarding':
      return {
        ...state,
        onboarding: action.payload,
      }

    case 'changeRouting':
      return {
        ...state,
        currentRoutes: action.payload,
      }
    case 'changeRelationship':
      return {
        ...state,
        relationship: action.payload,
      }
    case 'changeGender':
      return {
        ...state,
        gender: action.payload,
      }

    case 'changeBirthday':
      return {
        ...state,
        birthday: action.payload,
      }

    case 'changeIsBornTime':
      return {
        ...state,
        isBornTime: action.payload,
      }
    case 'changeBornTime':
      return {
        ...state,
        bornTime: action.payload,
      }
    case 'changeBornPlace':
      return {
        ...state,
        bornPlace: action.payload,
      }
    case 'setPhotoData':
      return {
        ...state,
        photoData: action.payload,
      }

    case 'changeEmailUser':
      return {
        ...state,
        emailUser: action.payload,
      }

    case 'changeGtagClientId':
      return {
        ...state,
        gtagClientId: action.payload,
      }

    case 'changeTarget':
      return {
        ...state,
        target: action.payload,
      }
    case 'changeVariant':
      return {
        ...state,
        variant: action.payload,
      }

    case 'changePartner':
      return {
        ...state,
        partner: action.payload,
      }

    case 'changePlanCodeData':
      return {
        ...state,
        planCodeData: action.payload,
      }
    case 'changeWish':
      return {
        ...state,
        wish: action.payload,
      }
    default:
      return state
  }
}

export default reducer
