import clsx from 'clsx'
import { geocodingSearchLink, isDev } from 'config'
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import useRunFunctionDelay from 'utils/hooks/useRunFunctionDelay'

import Input from 'components/input'

import styles from './styles.module.scss'

type Props = {
  textError?: string
  placeholder?: string
  place: string
  classNameInput?: string
  classNameList?: string
  onSelectPlace: (place: string) => void
  onClear: () => void
  setError: (isError: boolean) => void
}
type Option = {
  id: string
  value: string
}

const GeoPlaceSelector: FC<Props> = ({
  textError,
  placeholder,
  place,
  classNameInput,
  classNameList,
  onSelectPlace,
  onClear,
  setError,
}) => {
  const [value, setValue] = useState(place)
  const [options, setOptions] = useState<Array<Option>>([])
  const [loading, setLoading] = useState(false)
  const [openList, setOpenList] = useState(false)

  const ref = useRef('')

  const getPlaces = async (newValue: string) => {
    setLoading(true)
    await fetch(`${isDev ? geocodingSearchLink : 'https://geocoding.hint.app/api/v1/search'}?name=${newValue}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        setOptions(
          data.predictions.map((item: { description: string; place_id: string }) => ({
            value: item.description,
            id: item.place_id,
          })),
        )
        setOpenList(true)
        setError(false)
      })
      .catch(() => {
        setOptions([])
        setError(true)
        setOpenList(false)
      })
      .finally(() => setLoading(false))
  }

  const delayGetPlaces = useRunFunctionDelay(getPlaces)

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    ref.current = ''
    setError(false)

    const newValue = event.currentTarget.value

    setValue(newValue)

    delayGetPlaces(newValue)
  }

  const onClearInput = () => {
    onClear()

    setValue('')
    setOptions([])
    setError(false)
    setOpenList(false)
  }

  const onSelect = (newPlace: string) => {
    setValue(newPlace)
    onSelectPlace(newPlace)

    setError(false)
    setOpenList(false)
    ref.current = newPlace
  }

  const onBlur = useCallback(() => {
    setTimeout(() => {
      setError(!ref.current)

      setOpenList(false)
    }, 200)
  }, [setError])

  const onFocus = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value
    if (newValue) {
      delayGetPlaces(newValue)
    }
    setError(false)
  }

  return (
    <div className={styles.dynamicDropdown}>
      <Input
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onClearInput={onClearInput}
        loading={loading}
        className={clsx(styles.inputPlace, classNameInput)}
      />
      <ul className={clsx(styles.list, !!openList && styles.listOpened, !!openList && classNameList)}>
        {options.map((option) => (
          <li className={styles.option} key={option.id}>
            <div onClick={() => onSelect(option.value)}>{option.value}</div>
          </li>
        ))}
      </ul>
      <h3 className={clsx(styles.errorText, textError && styles.showError)}>{textError}</h3>
    </div>
  )
}

export default GeoPlaceSelector
