import { ReactComponent as Arrow } from 'assets/svg/arrow-head.svg'
import { ReactComponent as EmailImg } from 'assets/svg/email.svg'
import clsx from 'clsx'
import { ChangeEvent, FC, useState } from 'react'
import { useDispatch, useSelect } from 'store/hook'
import { analyticEvent } from 'utils/analytics'
import { validateEmail } from 'utils/helpers'

import ButtonBack from 'components/button-back'
import Input from 'components/compatibility/input'
import NextButton from 'components/compatibility/next-button'
import ContentWrapper from 'components/content-wrapper'

import styles from './styles.module.scss'

export const Email: FC = () => {
  const dispatch = useDispatch()
  const { emailUser } = useSelect()
  const [email, setEmail] = useState(emailUser)

  const [isError, setIsError] = useState(false)

  const handleNextPage = () => {
    dispatch({
      type: 'changeEmailUser',
      payload: email,
    })
    analyticEvent({
      name: 'CompatibilityOnboardingEmailClicked',
    })
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setEmail(value)
  }

  const onBlur = () => {
    setIsError(!validateEmail(email))
  }

  const onFocus = () => {
    setIsError(false)
  }

  return (
    <ContentWrapper>
      <ButtonBack className={styles.buttonBack}>
        <div className={styles.buttonContent}>
          <Arrow />
          Back
        </div>
      </ButtonBack>
      <h3 className={styles.title}>
        We will let you know about interesting astrological events and how they will affect you, With Hint, you won’t
        miss a thing!
      </h3>
      <EmailImg className={styles.img} />
      <div className={clsx(styles.formEmail, isError && styles.formEmailError)}>
        <Input
          value={email}
          placeholder="Your e-mail address"
          className={clsx(styles.inputEmail)}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <h3 className={clsx(styles.error)}>
          Please enter your email in format:
          <br /> name@example.com
        </h3>
      </div>
      <NextButton onClick={handleNextPage} disabled={!validateEmail(email)} />
      <p className={styles.policy}>
        We don’t share any personal information. We respect your privacy and are committed to protecting your personal
        data.
      </p>
    </ContentWrapper>
  )
}

export default Email
