import { useLocation } from 'react-router-dom'
import { useSelect } from 'store/hook'

const useCurrentRouteIndex = () => {
  const { currentRoutes } = useSelect()
  const location = useLocation()
  const arrayPathName = location.pathname.split('/')
  const currentPath = `/${arrayPathName[1]}/${arrayPathName[2]}`

  const currentIndexRoute = currentRoutes.findIndex((route) => route.path === currentPath)

  return currentIndexRoute
}
export default useCurrentRouteIndex
