import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import useNextRoute from 'utils/hooks/useNextRoute'

import Button from 'components/button'

export type PropsNextButton = {
  title?: ReactNode
  disabled?: boolean
  className?: string
  to?: string
  onClick?: () => void
}
const NextButton: FC<PropsNextButton> = ({ to, title, disabled, className, onClick }) => {
  const onNextRoute = useNextRoute()
  const { t } = useTranslation()

  const handleNextPage = () => {
    onNextRoute(to)
    if (onClick) {
      onClick()
    }
  }
  return (
    <Button className={className} isActive={!disabled} disabled={disabled} onClick={handleNextPage}>
      {title ?? t('next-button-title')}
    </Button>
  )
}

export default NextButton
