import { ReactComponent as Friendship } from 'assets/svg/compatibility/friendship.svg'
import { ReactComponent as LoveHands } from 'assets/svg/compatibility/love-hands.svg'
import { ReactComponent as Work } from 'assets/svg/compatibility/work.svg'
import { useDispatch, useSelect } from 'store/hook'
import { TargetOptions } from 'types'
import { analyticEvent } from 'utils/analytics'
import useNextRoute from 'utils/hooks/useNextRoute'

import ButtonIcon from 'components/compatibility/button-icon'
import ProgressBar from 'components/compatibility/progress-bar'
import ContentWrapper from 'components/content-wrapper'

import styles from './styles.module.scss'

const contentsButton = [
  { title: 'love', icon: <LoveHands /> },
  { title: 'friendship', icon: <Friendship /> },
  { title: 'work', icon: <Work /> },
]

export const Target = () => {
  const nextRoute = useNextRoute()
  const dispatch = useDispatch()
  const { target } = useSelect()

  const onClick = (payload: TargetOptions) => {
    dispatch({ type: 'changeTarget', payload })
    analyticEvent({ name: 'CompatibilityOnboardingCompatibilityClicked', gtagData: payload })
    nextRoute()
  }

  return (
    <ContentWrapper>
      <ProgressBar />
      <h3 className={styles.title}>What compatibility are you interested in now?</h3>
      <div className={styles.buttonWrapper}>
        {contentsButton.map((item, index) => (
          <ButtonIcon
            onClick={() => onClick(item.title as TargetOptions)}
            title={item.title}
            icon={item.icon}
            key={index}
            isActive={target === item.title}
          />
        ))}
      </div>
    </ContentWrapper>
  )
}

export default Target
