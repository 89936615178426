import {
  Analyzing,
  Birthday,
  BornPlace,
  BornTime,
  Discount,
  Download,
  Email,
  Final,
  Partner,
  PartnerBirthday,
  PartnerBornPlace,
  PartnerBornTime,
  Paywall,
  PremiumBundle,
  Target,
  TargetVariant,
  Welcome,
  WelcomePartner,
  Zodiac,
} from 'pages/compatibility'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'

import Header from 'components/compatibility/header'
import PageWrapper from 'components/page-wrapper'

import { paths } from './paths'

export const routesCompatibility: Array<RouteObject> = [
  {
    path: 'compatibility/*',
    element: <Navigate to={paths.welcome} replace />,
  },
  {
    path: paths.welcome,
    element: <Welcome />,
  },
  {
    path: paths.birthday,
    element: <Birthday />,
  },
  {
    path: paths.bornPlace,
    element: <BornPlace />,
  },
  {
    path: paths.bornTime,
    element: <BornTime />,
  },
  {
    path: paths.target,
    element: <Target />,
  },
  {
    path: paths.targetVariant,
    element: <TargetVariant />,
  },

  {
    path: paths.partner,
    children: [
      {
        path: paths.partner,
        element: <Partner />,
      },
      {
        path: 'welcome-partner',
        element: <WelcomePartner />,
      },
      {
        path: 'partner-birthday',
        element: <PartnerBirthday />,
      },
      {
        path: 'partner-born-place',
        element: <PartnerBornPlace />,
      },
      {
        path: 'partner-born-time',
        element: <PartnerBornTime />,
      },
      {
        path: 'zodiac',
        element: <Zodiac />,
      },
    ],
  },
  {
    path: paths.analyzing,
    element: <Analyzing />,
  },
  {
    path: paths.email,
    element: <Email />,
  },
  {
    path: paths.final,
    element: <Final />,
  },
  {
    path: paths.paywall,
    element: <Paywall />,
  },
  {
    path: paths.discount,
    element: <Discount />,
  },
  {
    path: paths.premiumBundle,
    element: <PremiumBundle />,
  },
  {
    path: paths.download,
    element: <Download />,
  },
]

const Pages = () => {
  return useRoutes(routesCompatibility)
}

const CompatibilityOnboarding = () => {
  return (
    <PageWrapper>
      <Header />
      <Pages />
    </PageWrapper>
  )
}

export default CompatibilityOnboarding
