import { ReactComponent as CloseCircle } from 'assets/svg/close-circle.svg'
import clsx from 'clsx'
import { FC, InputHTMLAttributes } from 'react'

import CircleSpinner from '../circle-spinner'
import styles from './styles.module.scss'

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  isError?: boolean
  textError?: string
  placeholder?: string
  loading?: boolean
  onClearInput?: () => void
}
const Input: FC<InputProps> = ({
  className,
  onChange,
  placeholder,
  isError,
  textError = '',
  value = '',
  type = 'text',
  onClearInput,
  loading,
  ...props
}) => {
  return (
    <label className={clsx(styles.customField, className, isError && styles.error)}>
      <input value={value} onChange={onChange} type={type} placeholder=" " {...props} />

      {!!placeholder && <p className={styles.placeholder}>{placeholder}</p>}
      {!!textError && <p className={styles.errorText}>{textError}</p>}
      <div className={styles.iconWrapper}>
        {onClearInput && value && !loading && (
          <button className={styles.buttonClear} onClick={onClearInput}>
            <CloseCircle />
          </button>
        )}
        {loading && <CircleSpinner className={styles.spinner} />}
      </div>
    </label>
  )
}

export default Input
