import { ReactComponent as BankCard } from 'assets/svg/bank-card.svg'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { UserData } from 'types'

import CheckoutForm from 'components/checkout-form'
import Modal from 'components/modal'

import styles from './styles.module.scss'

type Props = {
  loadingPayCard: boolean
  errorSubmit: boolean
  className?: string
  onSubmit: (userData: UserData) => void
  setErrorSubmit: (value: boolean) => void
}

const ButtonModalCard: FC<Props> = ({ onSubmit, setErrorSubmit, className, loadingPayCard, errorSubmit }) => {
  const [isShowModal, setShowModal] = useState(false)

  const onToggleModal = () => {
    setShowModal((prev) => !prev)
  }

  return (
    <>
      <button onClick={onToggleModal} className={clsx(styles.buttonShowCard, className)}>
        <BankCard />
        <p>Credit / Debit Card</p>
      </button>
      {isShowModal && (
        <Modal open={isShowModal} onClose={() => setShowModal(false)}>
          <CheckoutForm
            errorSubmit={errorSubmit}
            loadingPayCard={loadingPayCard}
            onSubmit={onSubmit}
            onClose={() => setShowModal(false)}
            setErrorSubmit={setErrorSubmit}
          />
        </Modal>
      )}
    </>
  )
}

export default ButtonModalCard
