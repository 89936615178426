import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import {
  CanMakePaymentResult,
  PaymentRequest,
  PaymentRequestCompleteStatus,
  PaymentRequestTokenEvent,
  StripePaymentRequestButtonElementOptions,
} from '@stripe/stripe-js'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { PaymentType, UserData } from 'types'
import useUserData from 'utils/hooks/useUserData'

export type SubmitValue = {
  event: PaymentRequestTokenEvent
  price: number
  type: `${PaymentType}`
}

export type AppleGooglePayProps = {
  price: number
  currency: string
  children?: ReactNode
  height?: number
  onSubmit: (userData: UserData, complete?: (status: PaymentRequestCompleteStatus) => void) => void
  onReady: () => void
}
const AppleGooglePayButtons: FC<AppleGooglePayProps> = ({ price, height, currency, children, onSubmit, onReady }) => {
  const stripe = useStripe()
  const userData = useUserData()

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)

  const submitPayment = useCallback(
    (result: Record<string, boolean>) => (event: PaymentRequestTokenEvent) => {
      const type = result?.applePay ? 'applePay' : 'googlePay'
      const { token, complete } = event
      onSubmit(
        {
          stripeToken: token.id,
          name: token.card?.name as string,
          type,
          ...userData,
        },
        complete,
      )
    },
    [onSubmit, userData],
  )

  const paymentRequestOptions = useMemo(
    () => ({
      country: 'GB',
      currency: currency?.toLowerCase(),
      total: {
        label: 'Hint',
        amount: price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    }),
    [currency, price],
  )

  useEffect(() => {
    if (stripe) {
      if (!paymentRequest) {
        const createPaymentRequest = stripe?.paymentRequest(paymentRequestOptions)

        createPaymentRequest.canMakePayment().then((result: CanMakePaymentResult) => {
          if (result && (result.googlePay || result.applePay)) {
            createPaymentRequest.on('token', submitPayment(result))
            setPaymentRequest(createPaymentRequest)
          } else {
            onReady()
          }
        })
      }
      if (paymentRequest) {
        paymentRequest.update({
          total: {
            label: 'Hint',
            amount: price,
          },
        })
      }
    }
    if (!stripe && !paymentRequest) {
      onReady()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequestOptions, price, stripe])

  const options = useMemo<StripePaymentRequestButtonElementOptions | undefined>(() => {
    if (!paymentRequest) {
      return undefined
    }

    return {
      paymentRequest,
      style: {
        paymentRequestButton: {
          height: `${height || 48}px`,
          borderRadius: '10px',
        },
      },
    }
  }, [height, paymentRequest])

  if (!paymentRequest) return null

  return (
    <>
      <PaymentRequestButtonElement key={Math.random()} onReady={onReady} options={options} />
      {children}
    </>
  )
}

export default AppleGooglePayButtons
