import { useTranslation } from 'react-i18next'
import { useSelect } from 'store/hook'
import i18n from 'translations/i18n'

import PalmPhotoWrapper from 'components/palm-photo-wrapper'

import styles from './styles.module.scss'

const { t } = i18n
const options = [
  [t('ring-finger'), t('middle-finger')],
  [t('little-finger'), t('index-finger')],
  [t('heart-line'), t('head-line')],
  [t('fate-line'), t('life-line')],
]

const PalmPhoto = () => {
  const { t: translations } = useTranslation()
  const { photoData } = useSelect()
  if (!photoData) {
    return null
  }

  return (
    <section className={styles.palmPhotoSection}>
      <h2 className={styles.title}>{translations('palm-photo-title')}</h2>
      <div className={styles.wrapper}>
        <PalmPhotoWrapper imageUrl={`data:image/jpeg;base64,${photoData.image}`} />
        <ul className={styles.list}>
          {options.map((option, index) => (
            <li className={styles.item} key={index}>
              <p className={styles.option}>{option[0]}</p>
              <p className={styles.option}>{option[1]}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default PalmPhoto
