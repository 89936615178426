import { ReactComponent as Arrow } from 'assets/svg/arrow-head.svg'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { useSelect } from 'store/hook'
import { UserData } from 'types'
import { analyticEvent } from 'utils/analytics'
import { toDollars } from 'utils/format-cent-dollars'
import useNextRoute from 'utils/hooks/useNextRoute'
import usePayment from 'utils/hooks/usePayment'

import AppleGooglePay from 'components/apple-google-pay'
import ButtonModalCard from 'components/button-modal-card'
import CircleSpinner from 'components/circle-spinner'

import styles from './styles.module.scss'

type Props = { onBack?: () => void }

const Payment: FC<Props> = ({ onBack }) => {
  const state = useSelect()
  const planCodeData = state.planCodeData || { planCode: '1_week_19_13', count: 1321 }

  const payment = usePayment()
  const nextRoute = useNextRoute()
  const [errorSubmit, setErrorSubmit] = useState(false)
  const [loadingPayCard, setLoadingPayCard] = useState(false)
  const [loadedAppleGooglePay, setLoadedAppleGooglePay] = useState(true)

  const price = toDollars(planCodeData.count).replace(/(\.|,)00$/g, '')

  const onSubmit = async (userData: UserData) => {
    setLoadingPayCard(true)
    await payment({ ...userData, planCode: planCodeData.planCode })
      .then(() => {
        nextRoute()
        analyticEvent({
          name: 'CompatibilityOnboardingSubscriptionPurchased',
          gtagData: { paymentType: userData.type },
        })
      })
      .catch(() => {
        setErrorSubmit(true)
        setLoadingPayCard(false)
      })
      .finally(() => {
        setLoadedAppleGooglePay(false)
        setLoadingPayCard(false)
      })
  }

  const onReady = () => {
    setLoadedAppleGooglePay(false)
  }

  return (
    <>
      {onBack && (
        <div onClick={onBack} className={styles.buttonBack}>
          <div className={styles.buttonContent}>
            <Arrow /> Back
          </div>
        </div>
      )}
      <div className={styles.offer}>
        <h1>Special Offer</h1>
      </div>
      <h1 className={styles.title}>Start your 7-day trial</h1>
      <h4>No commitment. Cancel anytime.</h4>

      <div className={styles.todayCount}>
        <h1>Total today</h1>
        <h1>{price}</h1>
      </div>
      <div className={styles.weekCount}>
        <div className={styles.leftBlock}>
          <h4>Code HINT22 applied!</h4>
          <h4>Your cost per week after trial</h4>
          <h4>Save $10 every week</h4>
        </div>
        <div className={styles.rightBlock}>
          <h4>$29</h4>
          <h4>$19</h4>
        </div>
      </div>

      <p className={styles.description}>
        You will be charged only <b> {price} for your 7-day trial.</b> We’ll<b> email you a reminder</b> before your
        trial period ends.
      </p>
      <section className={styles.paymentWrapper}>
        <div className={clsx(styles.loaderWrapper, loadedAppleGooglePay && styles.loaderWrapperShow)}>
          <CircleSpinner />
        </div>

        <div className={clsx(styles.payment, loadedAppleGooglePay && styles.paymentHide)}>
          <AppleGooglePay height={56} price={planCodeData.count} currency="usd" onSubmit={onSubmit} onReady={onReady}>
            <div className={styles.divider}>OR</div>
          </AppleGooglePay>

          <ButtonModalCard
            className={styles.paymentButton}
            setErrorSubmit={setErrorSubmit}
            errorSubmit={errorSubmit}
            loadingPayCard={loadingPayCard}
            onSubmit={onSubmit}
          />
        </div>
      </section>
    </>
  )
}

export default Payment
