import { ReactComponent as Cross } from 'assets/svg/cross.svg'
import { FC } from 'react'

import Button from 'components/button'
import Modal from 'components/modal'

import styles from './styles.module.scss'

type Props = {
  open: boolean
  onClose: () => void
}

const ErrorModal: FC<Props> = ({ open, onClose }) => {
  return (
    <Modal open={open} className={styles.modalError}>
      <div className={styles.closeButton} onClick={() => onClose()}>
        <Cross />
      </div>
      <p>
        Oops!
        <br />
        Something went wrong. <br />
        Please make sure to follow the onscreen instructions.
      </p>
      <Button isActive onClick={() => onClose()}>
        Try again
      </Button>
    </Modal>
  )
}

export default ErrorModal
