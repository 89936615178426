import { useDispatch, useSelect } from 'store/hook'
import BornPlaceTemplate from 'templates-page/compatibility/born-place'
import { analyticEvent } from 'utils/analytics'

export const BornPlace = () => {
  const dispatch = useDispatch()
  const { bornPlace } = useSelect()

  const onSelectPlace = (payload: string) => {
    dispatch({
      type: 'changeBornPlace',
      payload,
    })
  }

  const onClear = () => {
    dispatch({
      type: 'changeBornPlace',
      payload: null,
    })
  }

  const handleNextPage = () => {
    if (bornPlace) {
      analyticEvent({ name: 'CompatibilityOnboardingPOBClicked', gtagData: bornPlace })
    }
  }

  return (
    <BornPlaceTemplate
      title="What is your place of birth?"
      description="Your exact birthplace lets us create your unique astrological blueprint."
      place={bornPlace || ''}
      onNextPage={handleNextPage}
      onSelectPlace={onSelectPlace}
      onClear={onClear}
    />
  )
}

export default BornPlace
