import { FC } from 'react'
import PaymentTemplate from 'templates-page/payment'

import ContentWrapper from 'components/content-wrapper'
import EmailHeader from 'components/palmistry/email-header'

export const Payment: FC = () => {
  return (
    <ContentWrapper>
      <EmailHeader />
      <PaymentTemplate />
    </ContentWrapper>
  )
}
