import { boards } from 'config'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'store/hook'
import { Onboarding } from 'types'
import { sha256 } from 'utils/helpers'

import CompatibilityOnboarding, { routesCompatibility } from './compatibility-onboarding'
import PalmistryOnboarding, { routesPalmistry } from './palmistry-onboarding'

const Onboardings = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const currentBoard = boards[location.pathname.split('/')[1] as Onboarding] || 'palmistry'

  const getOnboardings = () => {
    switch (currentBoard) {
      case 'compatibility':
        return { onboarding: <CompatibilityOnboarding />, routes: routesCompatibility }

      default:
        return { onboarding: <PalmistryOnboarding />, routes: routesPalmistry }
    }
  }
  const { onboarding, routes } = getOnboardings()

  useEffect(() => {
    dispatch({
      type: 'changeOnboarding',
      payload: currentBoard,
    })
    dispatch({
      type: 'changeRouting',
      payload: routes,
    })
  }, [currentBoard, routes, dispatch])

  useEffect(() => {
    const { gtag, fbq } = window

    gtag('get', 'G-S35JE8DVYJ', 'client_id', (clientId: string) => {
      dispatch({
        type: 'changeGtagClientId',
        payload: clientId,
      })

      sha256(clientId).then((token) => {
        fbq('init', `${process.env.REACT_APP_FB_PIXEL_API_KEY}`, { external_id: token })
        fbq('track', 'PageView')
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return onboarding
}

export default Onboardings
