import { ReactComponent as Arrow } from 'assets/svg/arrow-head.svg'
import { ReactComponent as Woman } from 'assets/svg/compatibility/astrology-women-3.svg'
import { useState } from 'react'
import SelectSubscription from 'templates-page/select-subscription'
import { analyticEvent } from 'utils/analytics'

import ButtonBack from 'components/button-back'
import ContentWrapper from 'components/content-wrapper'
import EmailHeader from 'components/email-header'

import Payment from './payment'
import styles from './styles.module.scss'

export const Paywall = () => {
  const [isShowPayment, setIsShowPayment] = useState(false)

  const onClick = () => {
    setIsShowPayment(true)
  }
  const onBack = () => setIsShowPayment(false)

  const onSelectPlan = (count: number) => {
    analyticEvent({
      name: 'CompatibilityOnboardingTrialPriceChosen',
      gtagData: `${count}`,
    })
  }

  return (
    <ContentWrapper className={styles.paywallWrapper}>
      <EmailHeader />
      {isShowPayment ? (
        <Payment onBack={onBack} />
      ) : (
        <>
          <ButtonBack className={styles.buttonBack}>
            <div className={styles.buttonContent}>
              <Arrow />
              Back
            </div>
          </ButtonBack>
          <SelectSubscription
            title="We’ve helped millions of people have happier, healthier relationships and we can do the same for you!"
            image={<Woman className={styles.womanImg} />}
            onSelect={onSelectPlan}
            onClick={onClick}
          />
        </>
      )}
    </ContentWrapper>
  )
}
export default Paywall
