import Expert3 from 'assets/avatars/2.png'
import Expert2 from 'assets/avatars/3.png'
import Expert1 from 'assets/avatars/7.png'
import { ReactComponent as ExpertsImg } from 'assets/svg/palmistry/experts.svg'
import { FC } from 'react'

import styles from './styles.module.scss'

const Experts: FC = () => {
  return (
    <section className={styles.experts}>
      <h1>Gain insights from experts</h1>
      <div className={styles.description}>
        <div className={styles.avatars}>
          <img src={Expert1} alt="avatar" />
          <img src={Expert2} alt="avatar" />
          <img src={Expert3} alt="avatar" />
        </div>
        <ExpertsImg />
        <p>Our astrologers create personalized astrological readings for your growth and transformation.</p>
      </div>
    </section>
  )
}

export default Experts
