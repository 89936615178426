import { boards } from 'config'

export const paths = {
  palmWelcome: `/${boards.palmistry}/welcome`,
  relationshipStatus: `/${boards.palmistry}/relationship-status`,
  gender: `/${boards.palmistry}/gender`,
  birthday: `/${boards.palmistry}/birthday`,
  bornTimeQuestion: `/${boards.palmistry}/born-time-question`,
  bornTime: `/${boards.palmistry}/born-time`,
  bornPlace: `/${boards.palmistry}/born-place`,
  palmScanInfo: `/${boards.palmistry}/scan-info`,
  palmUpload: `/${boards.palmistry}/upload`,
  // analyzingUploadPhoto: `/${boards.palmistry}/analyzing-upload-photo`,
  palmEmail: `/${boards.palmistry}/email`,
  palmPaywall: `/${boards.palmistry}/paywall`,
  palmPaywall1: `/${boards.palmistry}/paywall-1`,
  palmDownloadPage: `/${boards.palmistry}/download`,
  discount: `/${boards.palmistry}/discount`,
  premiumBundle: `/${boards.palmistry}/premium-bundle`,
  subscriptionPlan: `/${boards.palmistry}/subscription-plan`,
  payment: `/${boards.palmistry}/payment`,
  wish: `/${boards.palmistry}/wish`,
  scanPhoto: `/${boards.palmistry}/scan-photo`,
}
