import { paths } from 'onboardings/palmistry-onboarding/paths'
import { FC, ReactNode } from 'react'
import useNextRoute from 'utils/hooks/useNextRoute'

import Button from 'components/button'
import Container from 'components/container'
import TitlePage from 'components/title-page'

import styles from './styles.module.scss'

type Props = {
  title?: ReactNode
  text?: ReactNode
  activeOption?: string | null
  options: { [key: string]: string }
  onSaveOption?: (keyOption: string) => void
}

const SingleQuestionPage: FC<Props> = ({ title, text, options, onSaveOption, activeOption }) => {
  const onNextRoute = useNextRoute()

  const handleNextPage = (keyOption: string) => {
    if (onSaveOption) onSaveOption(keyOption)

    if (keyOption === 'career' || keyOption === 'health') {
      onNextRoute(paths.palmScanInfo)
    } else {
      onNextRoute()
    }
  }

  return (
    <Container className={styles.singleQuestionPageContainer}>
      {title && <TitlePage title={title} />}
      {text && <div className={styles.textWrapper}>{text}</div>}
      <div className={styles.buttonWrapper}>
        {Object.keys(options).map((option) => (
          <Button isActive={activeOption === option} onClick={() => handleNextPage(option)} key={option}>
            {options[option]}
          </Button>
        ))}
      </div>
    </Container>
  )
}

export default SingleQuestionPage
