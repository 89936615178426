import clsx from 'clsx'
import { FC, memo } from 'react'

import styles from './styles.module.scss'

type Props = {
  coord: [number, number]
  fingerName: string
  onComplete: (finger: string) => void
  onStart: (finger: string) => void
}

const PointSVG: FC<Props> = ({ coord, fingerName, onComplete, onStart }) => {
  const [x, y] = coord

  const onAnimationEnd = () => {
    onComplete(fingerName)
  }

  const onAnimationStart = () => {
    onStart(`${fingerName} finger`)
  }

  return (
    <svg x={x - 12} y={y - 12} height="24px" width="24px">
      <circle
        cx="50%"
        cy="50%"
        r="11"
        fill="white"
        opacity="0.3"
        className={clsx(styles.fingerPoint, styles[fingerName])}
        onAnimationEnd={onAnimationEnd}
        onAnimationStart={onAnimationStart}
      />
      <circle
        cx="50%"
        cy="50%"
        r="5"
        fill="#066FDE"
        stroke="white"
        strokeWidth="0.3"
        className={clsx(styles.fingerPoint, styles[fingerName])}
      />
    </svg>
  )
}

export default memo(PointSVG)
