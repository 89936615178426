import amex from 'assets/svg/amex-card.svg'
import diners from 'assets/svg/diners-card.svg'
import discover from 'assets/svg/discover-card.svg'
import mastercard from 'assets/svg/mastercard-card.svg'
import visa from 'assets/svg/visa-card.svg'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const CardHeader = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.cardHeader}>
      <h3>{t('card-header-title')}</h3>

      <div className={styles.cardList}>
        <img src={visa} alt="visa card" />
        <img src={mastercard} alt="mastercard" />
        <img src={amex} alt="amex card" />
        <img src={diners} alt="diners card" />
        <img src={discover} alt="discover card" />
      </div>
    </div>
  )
}

export default CardHeader
