import { eulaLink, privacyLink } from 'config'
import { Trans } from 'react-i18next'

import styles from './styles.module.scss'

const EmailPolicy = () => {
  const eulaLinkComponent = (
    <a href={eulaLink} target="_blank" rel="noreferrer nofollow">
      EULA
    </a>
  )

  const privacyLinkComponent = (
    <a href={privacyLink} target="_blank" rel="noreferrer nofollow">
      Privacy Notice
    </a>
  )

  return (
    <div className={styles.policy}>
      <p>
        <Trans i18nKey="email-policy-text" components={[eulaLinkComponent, privacyLinkComponent]} />
      </p>
    </div>
  )
}

export default EmailPolicy
