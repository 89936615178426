import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg'
import clsx from 'clsx'
import { paths } from 'onboardings/palmistry-onboarding/paths'
import { FC, HTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelect } from 'store/hook'
import useBackRoute from 'utils/hooks/useBackRoute'

import styles from './styles.module.scss'

type Props = HTMLAttributes<HTMLDivElement> & {
  isBackHistory?: boolean
}

const ButtonBack: FC<Props> = ({ isBackHistory = false, children, className, ...props }) => {
  const onBackRoute = useBackRoute()
  const navigate = useNavigate()
  const { wish } = useSelect()

  const onHandleBackRoute = () => {
    if (isBackHistory) {
      navigate(-1)
    }

    if (wish === 'career' || wish === 'health') {
      navigate(paths.wish)
    } else {
      onBackRoute()
    }
  }

  return (
    <div onClick={onHandleBackRoute} className={clsx(styles.buttonWrapper, className)} {...props}>
      {children ?? <ArrowLeft />}
    </div>
  )
}

export default ButtonBack
