import clsx from 'clsx'
import { FC, memo } from 'react'

import Button, { PropsNextButton } from 'components/next-button'

import styles from './styles.module.scss'

const NextButton: FC<PropsNextButton> = ({ title, className, ...props }) => {
  return <Button title={title || 'Continue'} className={clsx(styles.nextButton, className)} {...props} />
}

export default memo(NextButton)
