import clsx from 'clsx'
import { FC } from 'react'

import styles from './styles.module.scss'

type Props = {
  className?: string
}

const CircleSpinner: FC<Props> = ({ className }) => {
  return (
    <svg className={clsx(styles.spinner, className)} viewBox="0 0 50 50">
      <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
    </svg>
  )
}

export default CircleSpinner
