import avatar1 from 'assets/avatars/8.png'
import avatar2 from 'assets/avatars/9.png'
import avatar3 from 'assets/avatars/10.png'
import { forwardRef } from 'react'

import NextButton from 'components/next-button'

import styles from './styles.module.scss'
import Subscription from './subscription'

export type Ref = HTMLDivElement

const Payment = forwardRef<Ref>((_, ref) => {
  return (
    <section className={styles.payment}>
      <h1>Unlock predictions</h1>
      <Subscription />
      <p>Get Unlimited Access for both Palmistry and Astrology with 1:1 Personal Palmistry Advisor.</p>
      <div className={styles.users}>
        <img className={styles.avatar} src={avatar3} alt="avatar" />
        <img className={styles.avatar} src={avatar1} alt="avatar" />
        <img className={styles.avatar} src={avatar2} alt="avatar" />
        <p>
          Last week <b>hundreds of people</b> got astrology reading
        </p>
      </div>
      <div ref={ref}>
        <NextButton title="Try 7-day trial" className={styles.nextButton} />
      </div>
    </section>
  )
})

export default Payment
