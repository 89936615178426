import clsx from 'clsx'
import { FC, HTMLAttributes, ReactNode } from 'react'

import styles from './styles.module.scss'

type Props = {
  icon: ReactNode
  title: string
  isActive?: boolean
} & HTMLAttributes<HTMLButtonElement>

const ButtonIcon: FC<Props> = ({ icon, title, className, isActive, ...props }) => {
  return (
    <button className={clsx(styles.button, isActive && styles.activeButton, className)} {...props}>
      <h3>{title}</h3>
      {icon}
    </button>
  )
}

export default ButtonIcon
