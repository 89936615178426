import { useContext } from 'react'

import { StoreContext } from './store-provider'

export const useSelect = () => {
  const context = useContext(StoreContext)

  return context.state
}
export const useDispatch = () => {
  const context = useContext(StoreContext)

  return context.dispatch
}
