import { useSelect } from 'store/hook'
import { toDollars } from 'utils/format-cent-dollars'

import styles from './styles.module.scss'

const Description = () => {
  const state = useSelect()
  const planCodeData = state.planCodeData || { planCode: '1_week_19_13', count: 1321 }

  return (
    <p className={styles.description}>
      You will be charged only {toDollars(planCodeData.count)} for your 7-day trial. We’ll email you a reminder before
      your trial period ends. Cancel anytime.
    </p>
  )
}

export default Description
