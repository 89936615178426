import { routesPalmistry } from 'onboardings/palmistry-onboarding'

import { InitState } from './types'

const initState: InitState = {
  onboarding: 'palmistry',
  currentRoutes: routesPalmistry,
  relationship: null,
  gender: null,
  birthday: { year: null, month: null, day: null },
  isBornTime: null,
  bornTime: { hours: null, minutes: null, meridiem: null },
  bornPlace: null,
  photoData: null,
  emailUser: '',
  gtagClientId: null,
  target: null,
  variant: null,
  partner: null,
  planCodeData: { planCode: '1_week_19_13', count: 1321 },
  wish: null,
}

const state = () => {
  const hintStateStorage = localStorage.getItem('hint-state')
  if (hintStateStorage) {
    return JSON.parse(hintStateStorage)
  }
  return initState
}

export default state()
