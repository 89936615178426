import clsx from 'clsx'
import { subscriptionPlans } from 'config'
import { FC, ReactNode } from 'react'
import { useDispatch, useSelect } from 'store/hook'
import { PlanCodeData } from 'types'
import { toDollars } from 'utils/format-cent-dollars'

import Button from 'components/button'

import styles from './styles.module.scss'

type Props = {
  onClick: () => void
  onSelect?: (count: number) => void
  image?: ReactNode
  title: string
}

const SelectSubscription: FC<Props> = ({ title, image, onClick, onSelect }) => {
  const dispatch = useDispatch()
  const state = useSelect()
  const { planCodeData } = state

  const onSelectPlan = (plan: PlanCodeData) => {
    dispatch({
      type: 'changePlanCodeData',
      payload: plan,
    })
    if (onSelect) {
      onSelect(planCodeData.count)
    }
  }

  return (
    <>
      <h1 className={styles.title}>{title}</h1>

      {image}

      <div className={styles.plans}>
        {subscriptionPlans.map((plan) => (
          <div
            onClick={() => onSelectPlan(plan)}
            className={clsx(styles.plan, planCodeData.planCode === plan.planCode && styles.activePlan)}
            key={plan.count}
          >
            <h3>{toDollars(plan.count).replace(/(\.|,)00$/g, '')}</h3>
          </div>
        ))}
      </div>

      <h1 className={clsx(styles.subscriptionText, planCodeData.planCode === '1_week_19_13' && styles.blueColorText)}>
        It costs us $13.21 to compensate our Hint employees for the trial, but please choose the amount you are
        comfortable with.
      </h1>

      <Button onClick={onClick} className={styles.button}>
        Continue
      </Button>
    </>
  )
}
export default SelectSubscription
