import { ReactComponent as EmailImg } from 'assets/svg/email.svg'
import { FC } from 'react'

import NextButton from 'components/compatibility/next-button'
import ModalWrapper, { ModalProps } from 'components/modal'

import styles from './styles.module.scss'

type Props = Omit<ModalProps, 'children'>

const Modal: FC<Props> = ({ open }) => {
  return (
    <ModalWrapper open={open} className={styles.modal}>
      <div className={styles.content}>
        <h1>Congrats!</h1>
        <EmailImg />
        <p>You should receive your Bundle via email shortly!</p>
        <NextButton />
      </div>
    </ModalWrapper>
  )
}

export default Modal
