import { useSelect } from 'store/hook'

export const useTypePartner = () => {
  const { partner } = useSelect()
  if (!partner) {
    return null
  }
  if (partner === 'not-data') {
    return 'not-data'
  }

  if ('birthday' in partner && 'bornTime' in partner) {
    return 'bornTime'
  }

  return 'zodiac' in partner ? 'zodiac' : 'birthday'
}
