import { ReactComponent as Star } from 'assets/svg/compatibility/star-four-pointed.svg'

import styles from './styles.module.scss'

const items = [
  'Discover your talents, strengths and purpose',
  'Find out who you are compatible with and how to deepen your connections',
  'Have 1:1 chats with your personal astrologer',
  'Daily horoscopes with tailored practical advice',
  'And much more!',
]

const List = () => {
  return (
    <ul className={styles.list}>
      {items.map((item, index) => (
        <li key={index}>
          <Star /> <p>{item}</p>
        </li>
      ))}
    </ul>
  )
}

export default List
