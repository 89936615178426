import { FC, ReactNode } from 'react'
import useNextRoute from 'utils/hooks/useNextRoute'

import Button from 'components/button'
import Container from 'components/container'
import Policy from 'components/policy'

import styles from './styles.module.scss'

type Props = {
  image: ReactNode
  description?: ReactNode
  descriptionSmallText?: ReactNode
  footer?: ReactNode
  textButton: string
  title?: ReactNode
  subTitle?: string
  isShowPolicy?: boolean
  onNextPage?: () => void
}
const DescriptionImagePage: FC<Props> = ({
  image,
  title,
  subTitle,
  description,
  descriptionSmallText,
  textButton,
  footer,
  isShowPolicy = true,
  onNextPage,
}) => {
  const onNextRoute = useNextRoute()

  const handleNextPage = () => {
    if (onNextPage) onNextPage()
    onNextRoute()
  }

  return (
    <Container className={styles.container}>
      {subTitle && subTitle}
      {image}
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.content}>
        {description}
        {descriptionSmallText && <h4>{descriptionSmallText}</h4>}
        <Button onClick={handleNextPage} isActive>
          {textButton}
        </Button>
      </div>
      {isShowPolicy && (
        <div className={styles.policyWrapper}>
          <Policy />
        </div>
      )}
      {footer && footer}
    </Container>
  )
}

export default DescriptionImagePage
