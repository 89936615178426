import { ReactComponent as Comet } from 'assets/svg/comet.svg'
import { FC } from 'react'
import SelectSubscription from 'templates-page/select-subscription'
import useNextRoute from 'utils/hooks/useNextRoute'

import ContentWrapper from 'components/content-wrapper'
import EmailHeader from 'components/palmistry/email-header'

import styles from './styles.module.scss'

export const SelectPlan: FC = () => {
  const nextRoute = useNextRoute()

  const onClick = () => {
    nextRoute()
  }

  return (
    <ContentWrapper className={styles.selectSubscriptionWrapper}>
      <EmailHeader />
      <SelectSubscription
        title="We've helped millions of people to reveal the destiny of their love life and what the future holds for them and their families."
        image={<Comet className={styles.cometImg} />}
        onClick={onClick}
      />
    </ContentWrapper>
  )
}
