import { useEffect } from 'react'
import DownloadPage from 'templates-page/download'
import { analyticEvent } from 'utils/analytics'

import Container from 'components/container'

import styles from './styles.module.scss'

export const PalmDownloadPage = () => {
  useEffect(() => {
    analyticEvent({ name: 'PalmistryOnboardingSuccessScreenShown' })
  }, [])

  return (
    <Container className={styles.downloadContainer}>
      <DownloadPage />
    </Container>
  )
}
