import clsx from 'clsx'
import { FC, HTMLAttributes, ReactNode } from 'react'

import styles from './styles.module.scss'

type Props = {
  children: ReactNode
} & HTMLAttributes<HTMLElement>

const Container: FC<Props> = ({ children, className }) => {
  return <section className={clsx(styles.container, className)}>{children}</section>
}

export default Container
