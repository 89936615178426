import dayjs from 'dayjs'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import * as en from './en/en.json'
import * as fr from './fr/fr.json'

export const DEFAULT_LANG = 'en'

export const DEFAULT_VARIABLES = {
  hint: 'Hint',
}

export const listOfTranslations = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  'fr-FR': {
    translation: fr,
  },
}

const params = new URLSearchParams(document.location.search)
const language = params.get('language')

i18n.use(initReactI18next).init({
  supportedLngs: ['en', 'fr-FR', 'fr'],
  returnObjects: true,
  fallbackLng: DEFAULT_LANG,
  lng: language || window.navigator.language || window.navigator.userLanguage,
  resources: {
    ...listOfTranslations,
  },
  interpolation: {
    defaultVariables: {
      ...DEFAULT_VARIABLES,
      escapeValue: false,
    },
  },
  react: {
    transWrapTextNodes: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'em', 's', 'b', 'a'],
  },
  ns: ['translation'],
  defaultNS: 'translation',
  debug: false,
})

require(`dayjs/locale/${i18n.resolvedLanguage}.js`)

dayjs.locale(i18n.resolvedLanguage)

export default i18n
