import { useDispatch, useSelect } from 'store/hook'
import BornTimeTemplate from 'templates-page/compatibility/born-time'
import { BornTime as BornTimeType } from 'types'
import { analyticEvent } from 'utils/analytics'
import { upperCaseFirstLetter } from 'utils/upperCaseFirstLetter'

export const PartnerBornTime = () => {
  const { partner, variant } = useSelect()
  const dispatch = useDispatch()

  if (!partner || partner === 'not-data') {
    return null
  }

  const onNextPage = (bornTime: BornTimeType) => {
    dispatch({
      type: 'changePartner',
      payload: { ...partner, bornTime },
    })
    analyticEvent({
      name: 'CompatibilityOnboardingKnowEverythingTOBPassed',
    })
  }
  return (
    <BornTimeTemplate
      title={`What is your ${upperCaseFirstLetter(variant || '')}’s time of birth?`}
      description="Knowing the exact position of the planets at the time of birth completes the map."
      isShowSkip={false}
      bornTime={partner.bornTime as BornTimeType}
      pages={3}
      currentPage={3}
      isBackHistory
      onNextPage={onNextPage}
    />
  )
}
export default PartnerBornTime
