import { FC, SyntheticEvent } from 'react'

import styles from './styles.module.scss'

type Props = {
  value: string
  onChange: (value: string) => void
}

const Switch: FC<Props> = ({ value, onChange }) => {
  const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value)
  }

  return (
    <label className={styles.switch}>
      <input value={value} onChange={handleChange} type="checkbox" className={styles.input} />
      <span className={styles.slider} />
    </label>
  )
}

export default Switch
