import dayjs from 'dayjs'
import { ChangeEvent, FC, memo, useState } from 'react'
import { zeroFormat } from 'utils/zeroFormatString'

import Input from 'components/compatibility/input'
import { Option } from 'components/select'

type Props = {
  className?: string
  month?: Option | null
  setMonth: (month: Option) => void
  setError: (isError: boolean) => void
}

const currentDate = dayjs()
const currentYear = currentDate.get('year')

const InputMonth: FC<Props> = ({ month, setMonth, setError, className }) => {
  const [errorMonth, setErrorMonth] = useState(false)

  const onChangeMonth = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    const currentMonth = dayjs(`${currentYear}-${zeroFormat(value)}`).format('MMM')

    if (+zeroFormat(value) <= 12) {
      setMonth({ value: zeroFormat(value), displayValue: currentMonth })

      setError(false)
      setErrorMonth(false)
    }
  }

  const onBlurMonth = () => {
    if (month?.value && +month.value <= 0) {
      setError(true)
      setErrorMonth(true)
    }
  }

  const onFocusMonth = () => {
    setError(false)
    setErrorMonth(false)
  }

  return (
    <div className={className}>
      <h3>Month</h3>
      <Input
        value={month?.value}
        isError={errorMonth}
        placeholder="MM"
        onChange={onChangeMonth}
        onBlur={onBlurMonth}
        onFocus={onFocusMonth}
      />
    </div>
  )
}

export default memo(InputMonth)
