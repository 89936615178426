import { FC } from 'react'

import Header from 'components/email-header'

import styles from './styles.module.scss'

const EmailHeader: FC = () => {
  return <Header className={styles.header} />
}

export default EmailHeader
