import { useDispatch, useSelect } from 'store/hook'
import BirthdayTemplate from 'templates-page/birthday'
import { BirthdayType } from 'types'
import { analyticEvent } from 'utils/analytics'

import ProgressBar from 'components/compatibility/progress-bar'
import ContentWrapper from 'components/content-wrapper'

export const Birthday = () => {
  const dispatch = useDispatch()
  const { birthday } = useSelect()
  const { year, month, day } = birthday
  const onNextPage = (payload: BirthdayType) => {
    dispatch({
      type: 'changeBirthday',
      payload,
    })
    analyticEvent({
      name: 'CompatibilityOnboardingDOBClicked',
      gtagData: `${day?.value}-${month?.value}-${year?.value}`,
    })
  }

  return (
    <ContentWrapper>
      <ProgressBar />
      <BirthdayTemplate
        title="What is your date of birth?"
        description="Your birth date reveals your core personality traits, needs and desires."
        birthday={birthday}
        onNextPage={onNextPage}
      />
    </ContentWrapper>
  )
}

export default Birthday
