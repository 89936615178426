import { ReactComponent as Headphone } from 'assets/svg/headphone.svg'
import { ReactComponent as Setting } from 'assets/svg/setting.svg'
import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'

import styles from './styles.module.scss'

const FooterPaywall: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  return (
    <footer className={clsx(styles.footer, className)} {...props}>
      <h4>Questions? We’re here to help.</h4>
      <div className={styles.links}>
        <a href="https://help.hint.app/en/articles/5783844-get-help-from-hint-support">
          <Headphone /> <h3>Customer Support</h3>
        </a>
        <a href=" https://help.hint.app/en/">
          <Setting /> <h3>Help Center</h3>
        </a>
      </div>
      <p>
        © 2022, RLabs America Inc DBA Hint
        <br />
        112 S.French Street, Suite 105, Wilmington, DE 19801, US
      </p>
    </footer>
  )
}

export default FooterPaywall
