import { ReactComponent as Cancer } from 'assets/svg/palmistry/cancer.svg'
import { ReactComponent as Taurus } from 'assets/svg/palmistry/taurus.svg'
import { FC } from 'react'

import styles from './styles.module.scss'

const properties = [
  { title: 'Love', progress: 85 },
  { title: 'Marriage', progress: 78 },
  { title: 'Friendship', progress: 64 },
  { title: 'Business', progress: 53 },
]

const Zodiac: FC = () => {
  return (
    <section className={styles.zodiac}>
      <h1>Zodiac Compatibility</h1>
      <p>
        We use algorithms for big data analytics to produce the most accurate results in conjunction with the decoding
        of these data by professional astrologers.
      </p>
      <div className={styles.info}>
        <div className={styles.plus}>+</div>
        <div className={styles.column}>
          <Taurus />
          <h2>Taurus</h2>
          <h3>21 Apr - 21 May</h3>
        </div>
        <div className={styles.column}>
          <Cancer />
          <h2>Cancer</h2>
          <h3>22 June - 22 July</h3>
        </div>
        <ul className={styles.propertiesList}>
          {properties.map((item, index) => (
            <li className={styles.itemProp} key={index}>
              <h3>{item.title}</h3>
              <div className={styles.progress}>
                <div style={{ width: `${item.progress}%` }} />
              </div>
              <p>{item.progress}%</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Zodiac
