import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useCallback, useState } from 'react'
import { BirthdayType } from 'types/page-options'

import NextButton from 'components/compatibility/next-button'
import { Option } from 'components/select'

import InputDay from './input-day'
import InputMonth from './input-month'
import InputYear from './input-year'
import styles from './styles.module.scss'

const currentDate = dayjs()
const hundredYears = 100
const hundredYearAgo = currentDate.get('year') - hundredYears

type Props = {
  to?: string
  title: string
  description: string
  birthday?: BirthdayType
  onNextPage: (birthday: BirthdayType) => void
}

const ERROR_MESSAGE = 'Date not found. Please check your details and try again.'

const Birthday: FC<Props> = ({ to, title, description, birthday, onNextPage }) => {
  const [year, setYear] = useState(birthday?.year)
  const [month, setMonth] = useState(birthday?.month)
  const [day, setDay] = useState(birthday?.day)
  const [errorDate, setErrorDate] = useState({ year: false, month: false, day: false })

  const isShowError = errorDate.year || errorDate.month || errorDate.day

  const isValidNextStep =
    (month?.value && +month.value <= 0) ||
    (day?.value && +day.value <= 0) ||
    (year?.value && +year.value < hundredYearAgo) ||
    !day?.value ||
    !month?.value ||
    !year?.value

  const setErrorYear = useCallback((error: boolean) => {
    setErrorDate((prev) => ({ ...prev, year: error }))
  }, [])
  const setErrorMonth = useCallback((error: boolean) => {
    setErrorDate((prev) => ({ ...prev, month: error }))
  }, [])
  const setErrorDay = useCallback((error: boolean) => {
    setErrorDate((prev) => ({ ...prev, day: error }))
  }, [])

  const onChangeYear = useCallback((newYear: Option) => {
    setYear(newYear)
  }, [])

  const onChangeMonth = useCallback((newMonth: Option) => {
    setMonth(newMonth)
  }, [])
  const onChangeDay = useCallback((newDay: Option) => {
    setDay(newDay)
  }, [])

  const onHandleNextPage = () => {
    if (year && month && day) {
      onNextPage({ year, month, day })
    }
  }

  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      <div className={styles.formBirthday}>
        <div className={styles.inputWrapper}>
          <InputYear className={styles.inputItem} year={year} setYear={onChangeYear} setError={setErrorYear} />
          <InputMonth className={styles.inputItem} month={month} setMonth={onChangeMonth} setError={setErrorMonth} />
          <InputDay className={styles.inputItem} month={month} day={day} setDay={onChangeDay} setError={setErrorDay} />
        </div>
        <h3 className={clsx(styles.errorText, isShowError && styles.showError)}>{ERROR_MESSAGE}</h3>
      </div>
      <NextButton to={to} onClick={onHandleNextPage} disabled={!!isValidNextStep} />
    </>
  )
}
export default Birthday
