import { PaymentRequestCompleteStatus } from '@stripe/stripe-js'
import { baseURL, getTokenLink, signupLink } from 'config'
import { sha512 } from 'js-sha512'
import { useCallback } from 'react'
import { useSelect } from 'store/hook'
import { TokenDataResponse, TokenResponse, UserData } from 'types'

const usePayment = () => {
  const { emailUser } = useSelect()

  const GenerateTokenAndHash = useCallback(async (): Promise<TokenDataResponse> => {
    const getToken: TokenResponse = await fetch(`${baseURL}${getTokenLink}`, {
      method: 'POST',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => data)
    const { token } = getToken

    const hash = sha512.hmac(`${process.env.REACT_APP_SIGNUP_KEY}`, `${emailUser}${token}`)

    return { hash, token }
  }, [emailUser])

  const onSubmit = useCallback(
    async (userData: UserData, complete?: (status: PaymentRequestCompleteStatus) => void) => {
      const tokenAndHash = await GenerateTokenAndHash()

      const data = { ...tokenAndHash, ...userData }

      await fetch(`${baseURL}${signupLink}`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
      }).then((response) => {
        if (!response.ok) {
          throw Error()
        }

        if (complete) {
          complete('success')
        }
        return response
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [GenerateTokenAndHash],
  )
  return onSubmit
}
export default usePayment
