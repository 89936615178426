import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'

import styles from './styles.module.scss'

const ContentWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...props }) => {
  return (
    <div className={clsx(styles.contentWrapper, className)} {...props}>
      {children}
    </div>
  )
}

export default ContentWrapper
