import { useTranslation } from 'react-i18next'
import FormEmail from 'templates-page/form-email'

export const PalmEmailForm = () => {
  const { t } = useTranslation()
  return (
    <FormEmail
      title={t('palm-email-form-title')}
      description={t('palm-email-form-description')}
      buttonNextText={t('palm-email-form-next-button')}
    />
  )
}
