import { useDispatch, useSelect } from 'store/hook'
import BirthdayTemplate from 'templates-page/birthday'
import { BirthdayType } from 'types'
import { analyticEvent } from 'utils/analytics'
import { upperCaseFirstLetter } from 'utils/upperCaseFirstLetter'

import ProgressBar from 'components/compatibility/progress-bar'
import ContentWrapper from 'components/content-wrapper'

export const PartnerBirthday = () => {
  const { partner, variant } = useSelect()
  const dispatch = useDispatch()

  if (!partner || partner === 'not-data') {
    return null
  }
  const onNextPage = (birthday: BirthdayType) => {
    dispatch({
      type: 'changePartner',
      payload: { ...partner, birthday },
    })
    analyticEvent({
      name: 'CompatibilityOnboardingKnowEverythingDOBPassed',
    })
  }

  return (
    <ContentWrapper>
      <ProgressBar isBackHistory pages={'bornTime' in partner ? 3 : 2} currentPage={1} />
      <BirthdayTemplate
        to="../partner-born-place"
        title={`What is your ${upperCaseFirstLetter(variant || '')}’s date of birth?`}
        description="The birth date reveals core personality traits, needs and desires."
        birthday={partner.birthday ? partner.birthday : undefined}
        onNextPage={onNextPage}
      />
    </ContentWrapper>
  )
}

export default PartnerBirthday
