/* eslint-disable no-console */
import { isDev } from 'config'
import { Onboarding } from 'types'

type Arg = {
  name: string
  gtagData?: { [key: string]: unknown } | [] | string
}

type AnalyticEvent = (arg: Arg) => void

const currentOnboarding = localStorage.getItem('onboarding') as Onboarding

const eventLabel = {
  palmistry: '<Palmistry>',
  compatibility: '<Compatibility>',
}

const trackGTagAnalytic: AnalyticEvent = ({ name, gtagData = '' }) => {
  if (Array.isArray(gtagData) || typeof gtagData === 'string') {
    gtagData = { value: gtagData }
  }

  const params = {
    event_category: '<WebOnboarding>',
    event_label: eventLabel[currentOnboarding],
    ...gtagData,
  }

  try {
    window.gtag('event', name, params)
  } catch (error: unknown) {
    if (isDev) console.error(`Failed to sent gtag event ${name}`, error)
  }
}

export const analyticEvent: AnalyticEvent = async ({ name, gtagData = '' }) => {
  trackGTagAnalytic({ name, gtagData })
}
