import { ReactComponent as Arrow } from 'assets/svg/arrow-head.svg'
import { ReactComponent as Woman } from 'assets/svg/compatibility/astrology-woman-2.svg'
import { FC } from 'react'

import NextButton from 'components/compatibility/next-button'
import ContentWrapper from 'components/content-wrapper'

import styles from './styles.module.scss'

type Props = {
  onBack: () => void
}

const NoTime: FC<Props> = ({ onBack }) => {
  return (
    <ContentWrapper className={styles.noTimeWrapper}>
      <button onClick={onBack} className={styles.buttonBack}>
        <Arrow /> Back
      </button>
      <h3 className={styles.text}>This will affect the accuracy of the analysis. But don’t worry.</h3>
      <Woman className={styles.imgWoman} />
      <h3 className={styles.text}>You can add this information later, and there is still so much we can tell you.</h3>
      <NextButton className={styles.nextButton} />
    </ContentWrapper>
  )
}

export default NoTime
