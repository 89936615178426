import Onboardings from 'onboardings'
import { BrowserRouter } from 'react-router-dom'
import StoreProvider from 'store/store-provider'

const App = () => {
  return (
    <StoreProvider>
      <BrowserRouter>
        <Onboardings />
      </BrowserRouter>
    </StoreProvider>
  )
}

export default App
