import { useTranslation } from 'react-i18next'
import { useSelect } from 'store/hook'

import Button from 'components/button'

import styles from './styles.module.scss'

const ButtonDownloadApp = () => {
  const { emailUser } = useSelect()
  const { t } = useTranslation()

  const linkDownload = `https://hint.onelink.me/hYTQ?deep_link_value=astrologyapp://email_login?email=${emailUser}`

  const handleClick = () => {
    window.open(linkDownload, '_blank')
  }
  return (
    <Button onClick={handleClick} className={styles.buttonDownload} isActive>
      {t('palm-download-button')}
    </Button>
  )
}

export default ButtonDownloadApp
