import { FC, useCallback, useState } from 'react'
import { useSelect } from 'store/hook'

import Finger from './finger'
import Line from './line'
import styles from './styles.module.scss'

type Props = {
  onCompleteScan: () => void
  getCurrentItem: (item: string) => void
}

const Photo: FC<Props> = ({ onCompleteScan, getCurrentItem }) => {
  const { photoData } = useSelect()
  const [isStartLines, setStartLines] = useState(false)

  const onStart = useCallback(
    (title: string) => {
      getCurrentItem(title)
    },
    [getCurrentItem],
  )

  const onCompleteLoadFinger = useCallback((fingerName: string) => {
    setStartLines(fingerName === 'little')
  }, [])

  const onCompleteLoadLine = useCallback(
    (lineName: string) => {
      if (lineName === 'heart') {
        onCompleteScan()
      }
    },
    [onCompleteScan],
  )

  if (!photoData) {
    return null
  }

  return (
    <>
      <img src={`data:image/jpeg;base64,${photoData.image}`} alt="Palm" className={styles.imagePalm} />
      <svg viewBox="0 0 291 477" className={styles.svgObjects}>
        {Object.entries(photoData.fingers).map((finger, index) => (
          <Finger
            onStart={onStart}
            onComplete={onCompleteLoadFinger}
            key={index}
            coord={finger[1]}
            fingerName={finger[0]}
          />
        ))}
        {isStartLines &&
          Object.entries(photoData.lines).map((line, index) => (
            <Line
              onStart={onStart}
              onComplete={onCompleteLoadLine}
              lineName={line[0]}
              key={index}
              coordinates={line[1]}
            />
          ))}
      </svg>
    </>
  )
}

export default Photo
