import screenAppPreview from 'assets/images/preview/screen-app.png'
import screenApp from 'assets/images/screen-app.png'
import { Trans, useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import ButtonDownloadApp from 'components/button-download-app'

import styles from './styles.module.scss'

const DownloadPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <h2 className={styles.title}>{t('palm-download-title')}</h2>
      <div className={styles.item}>
        <p>1.</p>
        <p>{t('palm-download-click-button')}</p>
      </div>
      <div className={styles.item}>
        <p>2.</p>
        <p>
          <Trans i18nKey="palm-download-see-image" />
        </p>
      </div>
      <div className={styles.imageWrapper}>
        <LazyLoadImage
          src={screenApp}
          width={239}
          height={168}
          alt="page's screenshot signup on app"
          placeholderSrc={screenAppPreview}
        />
      </div>
      <div className={styles.item}>
        <p>3.</p>
        <p>{t('palm-download-follow-instructions')}</p>
      </div>

      <ButtonDownloadApp />
    </>
  )
}
export default DownloadPage
