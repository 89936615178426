import { ReactComponent as CheckMark } from 'assets/svg/check-mark.svg'
import { ReactComponent as CorrectPalmImage } from 'assets/svg/correct-palm.svg'
import { ReactComponent as UnCorrectPalmImage1 } from 'assets/svg/un-correct-palm-1.svg'
import { ReactComponent as UnCorrectPalmImage2 } from 'assets/svg/un-correct-palm-2.svg'
import { ReactComponent as UnCorrectPalmImage3 } from 'assets/svg/un-correct-palm-3.svg'
import { ReactComponent as UnCorrectPalmImage4 } from 'assets/svg/un-correct-palm-4.svg'
import { FC } from 'react'

import BiometricData from 'components/biometric-data'
import Button from 'components/button'
import Spinner from 'components/circle-spinner'
import UploadModal from 'components/upload-modal'

import styles from './styles.module.scss'

type Props = {
  loading: boolean
  showMenuUpload: boolean
  setShowMenuUpload: (value: boolean) => void
  onShowCapturePhoto: () => void
  savePhoto: (imageUrl: string, typeUpload: string) => void
}

const StartView: FC<Props> = ({ loading, showMenuUpload, setShowMenuUpload, onShowCapturePhoto, savePhoto }) => {
  return (
    <>
      <h2 className={styles.title}>
        Take your palm picture as <br /> instructed
      </h2>
      <div className={styles.imageWrapper}>
        <p className={styles.correctTitle}>Correct</p>
        <div className={styles.wrapperCorrectPalmImage}>
          <CorrectPalmImage />
          <CheckMark className={styles.checkMark} />
        </div>
        <p className={styles.wrongTitle}>Wrong</p>
        <div className={styles.unCorrectImages}>
          <UnCorrectPalmImage1 />
          <UnCorrectPalmImage2 />
          <UnCorrectPalmImage3 />
          <UnCorrectPalmImage4 />
        </div>
      </div>

      <Button isActive={!loading} disabled={loading} onClick={() => setShowMenuUpload(true)}>
        {loading ? (
          <>
            <Spinner /> Loading photo
          </>
        ) : (
          'Take a picture now'
        )}
      </Button>
      <BiometricData />
      {showMenuUpload && (
        <>
          <div className={styles.wrapperModal}>
            <UploadModal savePhoto={savePhoto} onShowCapturePhoto={onShowCapturePhoto} />
          </div>
          <div onClick={() => setShowMenuUpload(false)} className={styles.mountModal} />
        </>
      )}
    </>
  )
}

export default StartView
