import { FC, PropsWithChildren, createContext, useEffect, useMemo, useReducer } from 'react'

import reducer from './reducer'
import initState from './state'
import { CreateContext } from './types'

export const StoreContext = createContext<CreateContext>({ state: initState, dispatch: () => initState })

const StoreProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  useEffect(() => {
    localStorage.setItem('hint-state', JSON.stringify(contextValue.state))
  }, [contextValue])

  return <StoreContext.Provider value={contextValue}>{children}</StoreContext.Provider>
}

export default StoreProvider
