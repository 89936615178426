import dayjs from 'dayjs'
import { ChangeEvent, FC, memo, useState } from 'react'

import Input from 'components/compatibility/input'
import { Option } from 'components/select'

type Props = {
  className?: string
  year?: Option | null
  setError: (isError: boolean) => void
  setYear: (year: Option) => void
}

const currentDate = dayjs()
const hundredYears = 100
const hundredYearAgo = currentDate.get('year') - hundredYears
const currentYear = currentDate.get('year')

const InputYear: FC<Props> = ({ className, year, setError, setYear }) => {
  const [errorYear, setErrorYear] = useState(false)

  const onChangeYear = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    const selectedYear = currentDate.year(+value)
    const isAfterYear = currentDate.isAfter(selectedYear)
    const isValidYear = currentYear === +value || isAfterYear

    if (isValidYear) {
      setYear({ value, displayValue: value })
    }
  }

  const onBlurYear = () => {
    if (year?.value && +year.value < hundredYearAgo) {
      setError(true)
      setErrorYear(true)
    }
  }

  const onFocusYear = () => {
    setError(false)
    setErrorYear(false)
  }

  return (
    <div className={className}>
      <h3>Year</h3>
      <Input
        max={4}
        isError={errorYear}
        value={year?.value}
        placeholder="YYYY"
        type="number"
        onChange={onChangeYear}
        onBlur={onBlurYear}
        onFocus={onFocusYear}
      />
    </div>
  )
}

export default memo(InputYear)
