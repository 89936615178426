/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'

const useRunFunctionDelay = (fn: (args?: any) => void, delay = 500) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null)

  const fnDelay = (...args: any) => {
    if (timer) {
      clearTimeout(timer)
    }
    setTimer(setTimeout(fn, delay, ...args))
  }
  return fnDelay
}
export default useRunFunctionDelay
