import { useDispatch, useSelect } from 'store/hook'
import BornTimeTemplate from 'templates-page/compatibility/born-time'
import { BornTime as BornTimeType } from 'types'
import { analyticEvent } from 'utils/analytics'

export const BornTime = () => {
  const { bornTime } = useSelect()
  const dispatch = useDispatch()

  const onNextPage = (time: BornTimeType) => {
    dispatch({
      type: 'changeBornTime',
      payload: time,
    })
    analyticEvent({ name: 'CompatibilityOnboardingTOBClicked', gtagData: 'Continue' })
  }
  const onSkipPage = () => {
    analyticEvent({ name: 'CompatibilityOnboardingTOBClicked', gtagData: 'Skip' })
  }

  return (
    <BornTimeTemplate
      title="What is your time of birth?"
      description="Knowing the exact position of the planets at the time of your birth completes the map."
      bornTime={bornTime}
      onNextPage={onNextPage}
      onSkipPage={onSkipPage}
    />
  )
}
export default BornTime
