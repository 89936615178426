import { ReactI18NextChild } from 'react-i18next'
import i18n from 'translations/i18n'

import ArrowLoading from 'components/arrow-loading'

import styles from './styles.module.scss'

const { t } = i18n

const DURATION = 500
const DELAY = 2000

const fingerOptions = [
  <p>{t('middle-finger') as ReactI18NextChild}</p>,
  <p>{t('ring-finger') as ReactI18NextChild} </p>,
  <p>{t('index-finger') as ReactI18NextChild} </p>,
  <p>{t('little-finger') as ReactI18NextChild}</p>,
]

const analyzingOptions = [
  <p>{t('analyzing-options-life') as ReactI18NextChild}</p>,
  <p>{t('analyzing-options-head') as ReactI18NextChild}</p>,
  <p>{t('analyzing-options-fate') as ReactI18NextChild}</p>,
  <p>{t('analyzing-options-heart') as ReactI18NextChild}</p>,
]
const featuresOptions = [
  <p>{t('features-options-palmprint') as ReactI18NextChild}</p>,
  <p>{t('features-options-observation') as ReactI18NextChild}</p>,
  <p>{t('features-options-length-palm') as ReactI18NextChild}</p>,
  <p>{t('features-options-width-palm') as ReactI18NextChild}</p>,
]

let currentIndex = 0

const blocksLoading = [fingerOptions, analyzingOptions, featuresOptions].map((item, index) => {
  return item.map((text) => {
    currentIndex++

    const currentDuration = DURATION * currentIndex + DELAY * index

    return { text, currentDuration }
  })
})

const AnalyzingBlocksInfo = () => {
  return (
    <div className={styles.wrapperBlocksInfo}>
      {blocksLoading.map((block, index) => (
        <div className={styles.blockInfo} key={index}>
          <ul className={styles.list}>
            {block.map((option, optionIndex) => (
              <li key={optionIndex}>
                {option.text}
                <ArrowLoading duration={option.currentDuration} />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

export default AnalyzingBlocksInfo
