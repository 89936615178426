import clsx from 'clsx'
import { ChangeEvent, FC, useState } from 'react'
import { BornTime as BornTimeType } from 'types'

import Button from 'components/button'
import Input from 'components/compatibility/input'
import NextButton from 'components/compatibility/next-button'
import ProgressBar from 'components/compatibility/progress-bar'
import Switch from 'components/compatibility/switch'
import ContentWrapper from 'components/content-wrapper'

import NoTime from './no-time'
import styles from './styles.module.scss'

type Props = {
  to?: string
  title: string
  description: string
  isShowSkip?: boolean
  bornTime: BornTimeType
  pages?: number
  currentPage?: number
  isBackHistory?: boolean
  onNextPage: (time: BornTimeType) => void
  onSkipPage?: () => void
}
const ERROR_MESSAGE = 'Please enter your time of birth in format: 05 23'

type Meridiem = 'AM' | 'PM'

const BornTime: FC<Props> = ({
  to,
  title,
  description,
  bornTime,
  pages,
  currentPage,
  onNextPage,
  onSkipPage,
  isBackHistory,
  isShowSkip = true,
}) => {
  const [isSkipTime, setSkipTime] = useState(false)

  const [time, setTime] = useState<BornTimeType>({ ...bornTime, meridiem: 'AM' })
  const [isError, setIsError] = useState(false)

  const isDisableNextButton = time.hours === null || time.minutes === null || !time.meridiem || isError

  const setHours = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    const isEmptyValue = value === ''
    const newHour = +value
    setIsError(isEmptyValue || newHour > 12 || newHour <= 0)
    setTime({ ...time, hours: isEmptyValue ? null : newHour })
  }

  const setMinutes = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    const isEmptyValue = value === ''
    const newMinutes = +value
    setIsError(isEmptyValue || newMinutes > 60 || newMinutes <= 0)
    setTime({ ...time, minutes: isEmptyValue ? null : newMinutes })
  }
  const onChangeMeridiem = (value: Meridiem) => {
    setTime({ ...time, meridiem: value === 'AM' ? 'PM' : 'AM' })
  }

  const handleNextPage = () => {
    onNextPage(time)
  }

  const onSkip = () => {
    setSkipTime(true)
    if (onSkipPage) {
      onSkipPage()
    }
  }

  return (
    <>
      {isSkipTime && <NoTime onBack={() => setSkipTime(false)} />}
      {!isSkipTime && (
        <ContentWrapper>
          <ProgressBar isBackHistory={isBackHistory} pages={pages} currentPage={currentPage} />
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{description}</p>
          <div className={styles.formTime}>
            <div className={styles.inputWrapper}>
              <Input
                onChange={setHours}
                value={time.hours || ''}
                className={clsx(styles.inputTime, isError && styles.inputError)}
                placeholder="HH"
              />
              <Input
                onChange={setMinutes}
                value={time.minutes || ''}
                className={clsx(styles.inputTime, isError && styles.inputError)}
                placeholder="MM"
              />
              <div className={styles.switchWrapper}>
                <div className={styles.switchLabel}>
                  <h1 className={clsx(time.meridiem === 'AM' && styles.activeLabel)}>AM</h1>
                  <h1 className={clsx(time.meridiem === 'PM' && styles.activeLabel)}>PM</h1>
                </div>
                <Switch onChange={onChangeMeridiem} value={time.meridiem || 'AM'} />
              </div>
            </div>
            <p className={clsx(styles.errorText, isError && styles.showError)}>{ERROR_MESSAGE}</p>
          </div>
          <NextButton to={to} disabled={isDisableNextButton} onClick={handleNextPage} />
          {isShowSkip && (
            <Button onClick={onSkip} className={styles.skipButton}>
              Skip for now
            </Button>
          )}
        </ContentWrapper>
      )}
    </>
  )
}
export default BornTime
