import amex from 'assets/svg/amex-card.svg'
import { ReactComponent as Cross } from 'assets/svg/cross.svg'
import diners from 'assets/svg/diners-card.svg'
import discover from 'assets/svg/discover-card.svg'
import emptyCard from 'assets/svg/grey-bank-card.svg'
import { ReactComponent as Lock } from 'assets/svg/lock.svg'
import mastercard from 'assets/svg/mastercard-card.svg'
import visa from 'assets/svg/visa-card.svg'
import clsx from 'clsx'
import {
  CardNumber,
  Cvv,
  ExpiryDate,
  FrameCardValidationChangedEvent,
  FramePaymentMethodChangedEvent,
  FrameValidationChangedEvent,
  Frames,
} from 'frames-react'
import { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserData } from 'types'
import useUserData from 'utils/hooks/useUserData'

import Button from 'components/button'
import CircleSpinner from 'components/circle-spinner'

import CardHeader from './card-header'
import Description from './description'
import Error from './error'
import { stylesForm } from './styles-form'
import styles from './styles.module.scss'

const publicKey = `${process.env.REACT_APP_CHECKOUT_PUBLIC_KEY}`

const CARD_TYPES: { [key: string]: string } = {
  Visa: visa,
  Mastercard: mastercard,
  'American Express': amex,
  'Diners Club': diners,
  Discover: discover,
}

type Props = {
  loadingPayCard: boolean
  errorSubmit: boolean
  onClose: () => void
  onSubmit: (userData: UserData) => void
  setErrorSubmit: (value: boolean) => void
}

const CheckoutForm: FC<Props> = ({ onSubmit, onClose, setErrorSubmit, loadingPayCard, errorSubmit }) => {
  const { t } = useTranslation()
  const userData = useUserData()
  const [nameOnCard, setNameOnCard] = useState('')
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [isValidCard, setValidCard] = useState(true)
  const [isValidForm, setValidForm] = useState(false)
  const [loadingPay, setLoadingPay] = useState(false)

  const isActiveCheckOutButton = isValidForm && !!nameOnCard

  const onChangeNameOnCard = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.currentTarget.value
    setNameOnCard(name)
  }

  const paymentMethodChanged = (event: FramePaymentMethodChangedEvent) => {
    setPaymentMethod(event.paymentMethod)
  }

  const cardValidationChanged = (event: FrameCardValidationChangedEvent) => {
    setValidForm(event.isValid)
  }
  const frameValidationChanged = (event: FrameValidationChangedEvent) => {
    const { element, isValid, isEmpty } = event
    if (element === 'card-number') {
      setValidCard(isEmpty ? true : isValid)
    }
  }

  const onCheckout = () => {
    if (token) {
      Frames.enableSubmitForm()
    }
    setLoadingPay(true)

    Frames.submitCard()
      .then((data) => {
        onSubmit({
          checkoutToken: data.token,
          name: nameOnCard,
          type: 'card',
          ...userData,
        })
      })
      .finally(() => {
        setLoadingPay(false)
      })
  }

  const onCloseForm = () => {
    setErrorSubmit(false)
    onClose()
  }

  return (
    <div className={styles.formWrapper}>
      {loading && <CircleSpinner />}
      {(loadingPay || loadingPayCard) && (
        <div className={styles.formBlocked}>
          <CircleSpinner />
        </div>
      )}
      <button className={styles.buttonClose} onClick={onCloseForm}>
        <Cross />
      </button>
      <form className={clsx(styles.form, !loading && styles.formShow, loadingPay && styles.formLoadingPay)}>
        <CardHeader />

        <div className={clsx(styles.wrapperError, errorSubmit && styles.showError)}>
          <Error />
        </div>

        <div className={styles.inputsForm}>
          <Frames
            config={{
              publicKey,
              localization: {
                cardNumberPlaceholder: t('card-number'),
                expiryMonthPlaceholder: t('mm'),
                expiryYearPlaceholder: t('yy'),
                cvvPlaceholder: t('cvv'),
              },
              style: stylesForm,
            }}
            ready={() => setLoading(false)}
            paymentMethodChanged={paymentMethodChanged}
            cardValidationChanged={cardValidationChanged}
            frameValidationChanged={frameValidationChanged}
            frameFocus={() => setErrorSubmit(false)}
            cardTokenized={(event) => {
              setToken(event.token)
            }}
          >
            <div className={styles.inputNameOnCard}>
              <input
                onFocus={() => setErrorSubmit(false)}
                value={nameOnCard}
                onChange={onChangeNameOnCard}
                placeholder={t('checkout-form-placeholder')}
                type="text"
              />
            </div>
            <div className={clsx(styles.cardNumberWrapper, !isValidCard && styles.cardNumberWrapperShowError)}>
              <div className={styles.frameInput}>
                <CardNumber className={styles.cardNumberInput} />
                <img className={styles.cardIcon} src={CARD_TYPES[paymentMethod] ?? emptyCard} alt="card icon" />
              </div>
              <div className={clsx(styles.errorCard, !isValidCard && styles.showErrorCard)}>
                {t('checkout-form-error-card')}
              </div>
            </div>
            <div className={styles.dateCvvCodeWrapper}>
              <ExpiryDate className={styles.frameInput} />
              <Cvv className={styles.frameInput} />
            </div>
          </Frames>
        </div>
        <Description />
        <Button
          disabled={!isActiveCheckOutButton}
          isActive={isActiveCheckOutButton}
          className={clsx(styles.checkoutButton, !isActiveCheckOutButton && styles.checkoutButtonDisable)}
          onClick={onCheckout}
        >
          <Lock />
          {t('checkout-button-submit')}
        </Button>
      </form>
    </div>
  )
}

export default CheckoutForm
