import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelect } from 'store/hook'
import { PartnerOptions } from 'types'
import { analyticEvent } from 'utils/analytics'
import { useTypePartner } from 'utils/hooks/useTypePartner'

import Button from 'components/button'
import ProgressBar from 'components/compatibility/progress-bar'
import ContentWrapper from 'components/content-wrapper'

import styles from './styles.module.scss'

export const Partner = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { partner } = useSelect()
  const typePartner = useTypePartner()

  const onNextStep = (payload: PartnerOptions) => {
    dispatch({
      type: 'changePartner',
      payload,
    })

    analyticEvent({
      name: 'CompatibilityOnboardingPartnerKnowledgeClicked',
    })

    navigate('welcome-partner')
  }

  const onSelectBornTime = () => {
    if (partner && partner !== 'not-data' && 'birthday' in partner) {
      const isHaveTimeField = 'bornTime' in partner

      onNextStep({ ...partner, bornTime: isHaveTimeField ? partner.bornTime : null })
    } else {
      onNextStep({ birthday: null, bornPlace: null, bornTime: null })
    }
  }
  const onSelectBirthday = () => {
    if (partner && partner !== 'not-data' && 'birthday' in partner) {
      delete partner.bornTime

      onNextStep({ ...partner })
    } else {
      onNextStep({ birthday: null, bornPlace: null })
    }
  }

  return (
    <ContentWrapper>
      <ProgressBar />
      <h3 className={styles.title}>What do you know about the other person?</h3>
      <Button
        onClick={onSelectBornTime}
        className={clsx(styles.customButton, typePartner === 'bornTime' && styles.activeButton)}
      >
        I know everything! (Date, place and time of birth)
      </Button>
      <Button
        onClick={onSelectBirthday}
        className={clsx(styles.customButton, typePartner === 'birthday' && styles.activeButton)}
      >
        I know everything except the time of birth
      </Button>
      <Button
        onClick={() => onNextStep({ zodiac: null })}
        className={clsx(styles.customButton, typePartner === 'zodiac' && styles.activeButton)}
      >
        I’m sure about zodiac sign
      </Button>
      <Button
        onClick={() => onNextStep('not-data')}
        className={clsx(styles.customButton, typePartner === 'not-data' && styles.activeButton)}
      >
        I’m not sure of anything
      </Button>
    </ContentWrapper>
  )
}
export default Partner
