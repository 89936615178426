import { ReactComponent as Alert } from 'assets/svg/alert.svg'
import { FC } from 'react'

import styles from './styles.module.scss'

const Error: FC = () => {
  return (
    <div className={styles.error}>
      <Alert />
      <p>There was an error processing your payment. Please try again or contact tech support.</p>
    </div>
  )
}

export default Error
