import clsx from 'clsx'
import { ButtonHTMLAttributes, FC, ReactNode } from 'react'

import styles from './styles.module.scss'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode
  isActive?: boolean
}

const Button: FC<ButtonProps> = ({ className, onClick, disabled = false, isActive = false, children, ...props }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(styles.button, className, disabled && styles.buttonDisabled, isActive && styles.buttonActive)}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
