import { Trans } from 'react-i18next'

import styles from './styles.module.scss'

const serifs = Array.from({ length: 4 }, (_, index) => <div className={styles.serif} key={index} />)
const titleBlocks = [
  <Trans i18nKey="analyzing-title-finger" />,

  <Trans i18nKey="analyzing-title-palmprint" />,

  <Trans i18nKey="analyzing-title-comprehensive" />,
]
const AnalyzingProgressBar = () => {
  return (
    <div className={styles.wrapperProgressBar}>
      <div className={styles.progressBar} />
      <div className={styles.progress} />
      <div className={styles.wrapperSerifs}>{serifs}</div>
      <div className={styles.itemsProgressBar}>
        {titleBlocks.map((title, index) => (
          <div key={index} className={styles.item}>
            <p>{title}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AnalyzingProgressBar
