import { ReactComponent as Arrow } from 'assets/svg/arrow-head.svg'
import { ReactComponent as Cosmos } from 'assets/svg/compatibility/cosmos.svg'
import { ReactComponent as MoonDecor } from 'assets/svg/compatibility/moon-decor.svg'
import { ReactComponent as Moon } from 'assets/svg/compatibility/moon.svg'
import { ReactComponent as Sun } from 'assets/svg/compatibility/sun.svg'
import { paths } from 'onboardings/compatibility-onboarding/paths'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelect } from 'store/hook'
import { analyticEvent } from 'utils/analytics'
import { useTypePartner } from 'utils/hooks/useTypePartner'
import { upperCaseFirstLetter } from 'utils/upperCaseFirstLetter'

import Button from 'components/button'
import ContentWrapper from 'components/content-wrapper'

import styles from './styles.module.scss'

export const WelcomePartner: FC = () => {
  const navigate = useNavigate()
  const typePartner = useTypePartner()

  const { variant } = useSelect()

  const content = useMemo(
    () => ({
      'not-data': {
        text: 'Hmm... In this case, there is little we can say about your compatibility with this person. But there is still so much we can tell you about yourself and the people who are the best fit for you!',
        img: <Cosmos />,
        to: paths.analyzing,
      },
      zodiac: {
        text: 'Good! We know a lot about your compatibility with different signs. Let’s get started.',
        img: <MoonDecor />,
        to: '../zodiac',
      },
      birthday: {
        text: 'This will affect the accuracy of the results a bit, but we can still say a lot about your compatibility.',
        img: <Moon />,
        to: '../partner-birthday',
      },
      bornTime: {
        text: `Wonderful! We have a lot to say about your compatibility. Tell us more about your ${upperCaseFirstLetter(
          variant || '',
        )}`,
        img: <Sun />,
        to: '../partner-birthday',
      },
    }),
    [variant],
  )

  const onClick = () => {
    navigate(paths.partner)
  }

  const nextStep = () => {
    navigate(content[typePartner || 'not-data'].to)
    analyticEvent({
      name: 'CompatibilityOnboardingPartnerIntro',
    })
  }

  return (
    <ContentWrapper className={styles.partnerWrapper}>
      <button onClick={onClick} className={styles.buttonBack}>
        <Arrow /> Back
      </button>
      {content[typePartner || 'not-data'].img}
      <h3 className={styles.text}>{content[typePartner || 'not-data'].text}</h3>
      <Button onClick={nextStep} className={styles.nextButton}>
        Continue
      </Button>
    </ContentWrapper>
  )
}

export default WelcomePartner
