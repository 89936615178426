import { isLocalhost } from 'utils/helpers'

export const isDev = process.env.REACT_APP_ENVIRONMENT === 'development'

export const baseURL = isDev ? 'https://dev01.dev.corp.hint.app' : 'https://payment.hint.app'

export const eulaLink = 'https://help.hint.app/en/articles/5744807-eula-end-user-license-agreement'

export const privacyLink = 'https://help.hint.app/en/articles/5744806-privacy-notice'

export const supportLink =
  'https://help.hint.app/en/?_gl=1*14im4wg*_ga*MTk4ODk3MDczMi4xNjYxMzI2MzQz*_ga_S35JE8DVYJ*MTY2MjQ3NTI1Ny4yMi4xLjE2NjI0NzUyNzkuMzguMC4w'

export const geocodingSearchLink = isLocalhost()
  ? '/geocoding/api/v1/search'
  : 'https://dev01.dev.corp.hint.app/geocoding/api/v1/search'

export const signupLink = isDev ? '/auth/api/v2/signup/web' : '/api/v2/signup/web'

export const getTokenLink = isDev ? '/auth/api/v2/token' : '/api/v2/token'

export const payAddonLink = isDev ? '/auth/api/v2/chargebee/addon' : '/api/v2/chargebee/addon'
export const upgradePlanLink = isDev ? '/auth/api/v2/chargebee/upgrade' : '/api/v2/chargebee/upgrade'
