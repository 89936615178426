import {
  Birthday,
  Discount,
  Gender,
  PalmDownloadPage,
  PalmEmailForm,
  PalmScanInfo,
  PalmUpload,
  PalmWelcome,
  Payment,
  Paywall1,
  PremiumBundle,
  RelationshipStatus,
  ScanPhoto,
  SelectPlan,
  Wish,
} from 'pages/palmistry'
import { Navigate, Route, RouteObject, Routes } from 'react-router-dom'

import Header from 'components/header'
import PageWrapper from 'components/page-wrapper'

import { paths } from './paths'

export const routesPalmistry: Array<RouteObject> = [
  {
    path: paths.palmWelcome,
    element: <PalmWelcome />,
  },

  {
    path: paths.gender,
    element: <Gender />,
  },
  {
    path: paths.birthday,
    element: <Birthday />,
  },

  {
    path: paths.wish,
    element: <Wish />,
  },
  {
    path: paths.relationshipStatus,
    element: <RelationshipStatus />,
  },

  {
    path: paths.palmScanInfo,
    element: <PalmScanInfo />,
  },
  {
    path: paths.palmUpload,
    element: <PalmUpload />,
  },
  {
    path: paths.scanPhoto,
    element: <ScanPhoto />,
  },
  {
    path: paths.palmEmail,
    element: <PalmEmailForm />,
  },
  {
    path: paths.subscriptionPlan,
    element: <SelectPlan />,
  },
  {
    path: paths.palmPaywall1,
    element: <Paywall1 />,
  },
  {
    path: paths.payment,
    element: <Payment />,
  },
  {
    path: paths.discount,
    element: <Discount />,
  },
  {
    path: paths.premiumBundle,
    element: <PremiumBundle />,
  },
  {
    path: paths.palmDownloadPage,
    element: <PalmDownloadPage />,
  },
]

const PalmistryOnboarding = () => {
  return (
    <PageWrapper>
      <Header />
      <Routes>
        {routesPalmistry.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        <Route path="*" element={<Navigate to={paths.palmWelcome} replace />} />
      </Routes>
    </PageWrapper>
  )
}

export default PalmistryOnboarding
