import { ReactComponent as Money } from 'assets/svg/money-back.svg'
import { FC } from 'react'

import styles from './styles.module.scss'

const MoneyBack: FC = () => {
  return (
    <section className={styles.moneyBack}>
      <Money />
      <h1>100% Money-back guarantee</h1>
      <p>
        The main goal of the Hint team is to create the best for you. Therefore, we carefully designed our product and
        astrology believe in its quality. If you don’t notice any progress after using the app for at least a week, we
        are ready to make a complete refund within <b>14 days.</b>
      </p>
    </section>
  )
}

export default MoneyBack
