import { Trans } from 'react-i18next'
import { useDispatch, useSelect } from 'store/hook'
import SingleQuestionPage from 'templates-page/single-question-page'
import i18n from 'translations/i18n'
import { RelationshipOptions } from 'types'
import { analyticEvent } from 'utils/analytics'

const options = {
  single: i18n.t('relationship-status-option-single'),
  inRelationship: i18n.t('relationship-status-option-inRelationship'),
}

export const RelationshipStatus = () => {
  const dispatch = useDispatch()
  const { relationship } = useSelect()

  const onSaveOption = (key: RelationshipOptions) => {
    dispatch({
      type: 'changeRelationship',
      payload: key,
    })
    analyticEvent({ name: 'PalmistryOnboardingRelStatusClicked', gtagData: key })
  }

  return (
    <SingleQuestionPage
      onSaveOption={onSaveOption}
      options={options}
      title={<Trans i18nKey="relationship-status-title" />}
      activeOption={relationship}
    />
  )
}
