import { PlanCodeData } from 'types'

export * from './links'
export * from './boards'

export const subscriptionPlans: Array<PlanCodeData> = [
  { planCode: '1_week_19_1', count: 100 },
  { planCode: '1_week_19_5', count: 500 },
  { planCode: '1_week_19_9', count: 900 },
  { planCode: '1_week_19_13', count: 1321 },
]
