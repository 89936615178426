import clsx from 'clsx'
import { FC, useState } from 'react'
import useNextRoute from 'utils/hooks/useNextRoute'

import Container from 'components/container'

import Frame from './frame'
import Photo from './photo'
import styles from './styes.module.scss'

export const ScanPhoto: FC = () => {
  const onNextRoute = useNextRoute()
  const [title, setTitle] = useState('')
  const [isCompleteScan, setCompleteScan] = useState(false)

  const onCompleteScan = () => {
    setCompleteScan(true)
  }

  const onAnimationEnd = () => {
    onNextRoute()
  }

  return (
    <Container className={styles.container}>
      <h2 className={styles.title}>{title || <p className={styles.scanning}>Scanning...</p>}</h2>
      <div className={clsx(styles.frameWrapper, isCompleteScan && styles.smallWrapper)}>
        <div className={clsx(styles.imageWrapper, isCompleteScan && styles.smallPhoto)}>
          <div className={styles.stick} />
          <Photo getCurrentItem={setTitle} onCompleteScan={onCompleteScan} />
        </div>
        <Frame />
      </div>
      <h2 className={styles.waitTitle}>Please wait a moment.</h2>
      <h3 className={styles.description} onAnimationEnd={onAnimationEnd}>
        Palm scanning result is sending to our astrologers for comprehensive analysis.
      </h3>
    </Container>
  )
}
