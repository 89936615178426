import { FC } from 'react'
import useAnimatedProgressValue from 'utils/hooks/useAnimatedProgressValue'

import styles from './styles.module.scss'

const PROGRESS_DURATION_MS = 4000
const PROGRESS_BAR_RADIUS = 92
const PROGRESS_BAR_LENGTH = 2 * Math.PI * PROGRESS_BAR_RADIUS

type Props = {
  started?: boolean
  progressDuration?: number
  onComplete?: () => void
}

const CircleAnimatedProgress: FC<Props> = ({ started = true, progressDuration = PROGRESS_DURATION_MS, onComplete }) => {
  const progress = useAnimatedProgressValue({
    started,
    progressDuration,
    onComplete,
  })

  return (
    <div className={styles.progressContainer} role="progressbar">
      <svg className={styles.progress} viewBox="0 0 199 199" xmlns="http://www.w3.org/2000/svg">
        <circle className={styles.progressBar} r={PROGRESS_BAR_RADIUS} />
        <circle
          className={styles.progressValue}
          r={PROGRESS_BAR_RADIUS}
          strokeDasharray={`${progress * PROGRESS_BAR_LENGTH} ${PROGRESS_BAR_LENGTH}`}
        />
      </svg>
      {/* <div className={styles.percentWrapper}> */}
      <h1 className={styles.progressPercentage}>{`${Math.round(progress * 100)}%`}</h1>
      {/* </div> */}
    </div>
  )
}

export default CircleAnimatedProgress
