import { ReactComponent as Air } from 'assets/svg/palmistry/air.svg'
import { ReactComponent as Blue } from 'assets/svg/palmistry/blue-symbol.svg'
import { ReactComponent as CapricornSymbol } from 'assets/svg/palmistry/capricorn-symbol.svg'
import { ReactComponent as Capricorn } from 'assets/svg/palmistry/capricorn.svg'
import { ReactComponent as Saturn } from 'assets/svg/palmistry/saturn-symbol.svg'
import { FC } from 'react'

import styles from './styles.module.scss'

const properties = [
  {
    type: 'Sun Sign',
    title: 'Capricorn',
    img: <CapricornSymbol />,
  },
  {
    type: 'Planet',
    title: 'Saturn',
    img: <Saturn />,
  },
  {
    type: 'Element',
    title: 'Air',
    img: <Air />,
  },
  {
    type: 'Color',
    title: 'Blue',
    img: <Blue />,
  },
]

const PersonalPlan: FC = () => {
  return (
    <section className={styles.personalPlan}>
      <h1>We will prepare your personalized plan</h1>
      <div className={styles.plan}>
        <header className={styles.header}>
          <Capricorn />
          <div>
            <h2>You</h2>
            <h3>06 Jan 1998</h3>
          </div>
        </header>
        <ul className={styles.propertiesList}>
          {properties.map((item) => (
            <li key={item.type} className={styles.item}>
              <h3>{item.type}</h3>
              <h2>{item.title}</h2>
              {item.img}
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default PersonalPlan
