import { ReactComponent as Star4Point } from 'assets/svg/star-4-point.svg'
import i18n from 'translations/i18n'

import styles from './styles.module.scss'

const { t } = i18n
const options = [
  t('palm-paywall-description-options-love-life'),
  t('palm-paywall-description-options-family'),
  t('palm-paywall-description-options-financial-success'),
  t('palm-paywall-description-options-unlimited-access'),
]

const DescriptionOptions = () => {
  return (
    <ul className={styles.list}>
      {options.map((option, index) => (
        <li key={index}>
          <div className={styles.wrapperIcon}>
            <Star4Point />
          </div>
          <p>{option}</p>
        </li>
      ))}
    </ul>
  )
}

export default DescriptionOptions
