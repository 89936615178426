import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelect } from 'store/hook'
import { toDollars } from 'utils/format-cent-dollars'

import styles from './styles.module.scss'

const DISCOUNT = 10

const Subscription: FC = () => {
  const { t } = useTranslation()

  const { planCodeData } = useSelect()

  return (
    <div className={styles.subscription}>
      <div className={styles.firstBlock}>
        <h1>Total today</h1>
        <h1>{toDollars(planCodeData?.count || 0)}</h1>
      </div>

      <div className={styles.secondBlock}>
        <div>
          <p>{t('palm-cost-after-trial')}</p>
          <p className={styles.code}>{t('subscription-code-HINT22')}</p>
        </div>
        <div className={styles.pricesCount}>
          <p className={styles.discount}>${19 + DISCOUNT}</p>
          <p className={styles.price}>$19</p>
        </div>
      </div>
    </div>
  )
}

export default Subscription
