import { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

type Props = {
  title: ReactNode
}
const TitlePage: FC<Props> = ({ title }) => {
  return (
    <div className={styles.titleWrapper}>
      <h3>{title}</h3>
    </div>
  )
}

export default TitlePage
