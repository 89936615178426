import clsx from 'clsx'
import { FC, memo, useEffect, useRef, useState } from 'react'

import styles from './styles.module.scss'

type Props = {
  coordinates: [number, number][]
  lineName: string
  onComplete: (lineName: string) => void
  onStart: (lineName: string) => void
}

const Line: FC<Props> = ({ lineName, coordinates, onStart, onComplete }) => {
  const svgRef = useRef<SVGPathElement>(null)
  const [pathLength, setPathLength] = useState(0)

  useEffect(() => {
    if (svgRef.current) {
      setPathLength(svgRef.current.getTotalLength())
    }
  }, [])

  const pathData = coordinates
    .map((coord, index) => {
      const [x, y] = coord

      return `${index ? 'L' : 'M'} ${x} ${y}`
    })
    .join(' ')

  const onAnimationEnd = () => {
    onComplete(lineName)
  }

  const onAnimationStart = () => {
    onStart(`${lineName} line`)
  }

  return (
    <path
      className={clsx(styles.line, styles[lineName])}
      style={{ strokeDasharray: pathLength, strokeDashoffset: pathLength }}
      ref={svgRef}
      d={pathData}
      onAnimationEnd={onAnimationEnd}
      onAnimationStart={onAnimationStart}
    />
  )
}

export default memo(Line)
