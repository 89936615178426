import { ReactComponent as Hand } from 'assets/svg/hand-zodiac.svg'
import { ReactComponent as Logo } from 'assets/svg/logo-white.svg'
import { FC } from 'react'

import styles from './styles.module.scss'

const Welcome: FC = () => {
  return (
    <section className={styles.welcome}>
      <Logo />
      <div className={styles.blockTitle}>
        <h1>
          Find your <br /> happiness with <br /> highly personalized <br /> predictions
        </h1>
        <Hand />
      </div>
    </section>
  )
}

export default Welcome
