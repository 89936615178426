import { ChangeEvent, FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelect } from 'store/hook'
import { analyticEvent } from 'utils/analytics'
import { validateEmail } from 'utils/helpers'

import Container from 'components/container'
import EmailPolicy from 'components/email-policy'
import Input from 'components/input'
import NextButton from 'components/next-button'

import styles from './styles.module.scss'

type Props = {
  title: ReactNode
  description: ReactNode
  buttonNextText: ReactNode
}

const FormEmail: FC<Props> = ({ title, description, buttonNextText }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { emailUser } = useSelect()
  const [email, setEmail] = useState(emailUser)

  const handleNextPage = () => {
    dispatch({
      type: 'changeEmailUser',
      payload: email,
    })
    analyticEvent({ name: 'PalmistryOnboardingEmailClicked' })
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setEmail(value)
  }

  return (
    <Container className={styles.container}>
      <div className={styles.wrapperTitle}>
        <h2>{title}</h2>
      </div>
      <Input
        className={styles.inputEmail}
        type="email"
        placeholder={t('form-email-placeholder')}
        value={email}
        onChange={onChange}
      />
      <div className={styles.wrapperDescription}>
        <h3>{description}</h3>
      </div>
      <EmailPolicy />
      <NextButton
        className={styles.buttonNext}
        title={buttonNextText}
        disabled={!validateEmail(email)}
        onClick={handleNextPage}
      />
    </Container>
  )
}

export default FormEmail
