import { baseURL, upgradePlanLink } from 'config'
import { useCallback } from 'react'
import { useSelect } from 'store/hook'

const useUpgradePlan = () => {
  const { emailUser } = useSelect()

  const onSubmit = useCallback(
    async (coupon: string) => {
      await fetch(`${baseURL}${upgradePlanLink}`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ email: emailUser, coupon }),
      }).then((response) => {
        if (!response.ok) {
          throw Error()
        }

        return response
      })
    },
    [emailUser],
  )
  return onSubmit
}
export default useUpgradePlan
