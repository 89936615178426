import { ReactComponent as Logo } from 'assets/svg/compatibility/logo.svg'
import { FC } from 'react'

import styles from './styles.module.scss'

const Header: FC = () => {
  return (
    <header className={styles.header}>
      <Logo />
    </header>
  )
}

export default Header
