import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'store/hook'
import { PalmPhotoResponse } from 'types'
import { analyticEvent } from 'utils/analytics'
import useNextRoute from 'utils/hooks/useNextRoute'

import CapturePhotoModal from 'components/capture-photo-modal'
import Container from 'components/container'

import ErrorModal from './error-modal'
import StartView from './start-view'
import styles from './styles.module.scss'

const UploadPalmPhoto: FC = () => {
  const dispatch = useDispatch()
  const onNextRoute = useNextRoute()
  const [showMenuUpload, setShowMenuUpload] = useState(false)
  const [isShowCapturePhoto, setShowCapturePhoto] = useState(false)
  const [photoData, setPhotoData] = useState<PalmPhotoResponse | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    const { body } = document

    body.classList.add('height-initial')

    return () => {
      body.classList.remove('height-initial')
    }
  }, [])

  const onCloseModalError = () => {
    setError(false)
  }

  const savePhoto = async (image: string, typeUpload: string) => {
    setLoading(true)
    setShowMenuUpload(false)

    await fetch(`https://dev01.dev.corp.hint.app/palmistry/api/processing`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ image }),
    })
      .then((response) => response.json())
      .then((data: PalmPhotoResponse) => {
        if (!data.success) {
          setError(true)
        } else {
          setPhotoData(data)
          dispatch({ type: 'setPhotoData', payload: data })
          onNextRoute()
        }
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })

    analyticEvent({ name: 'PalmistryOnboardingPhotoUploadOptionClicked', gtagData: typeUpload })
  }

  const onCloseCapturePhoto = () => {
    setShowCapturePhoto(false)
  }

  const onShowCapturePhoto = () => {
    setShowCapturePhoto(true)
    setShowMenuUpload(false)
  }

  return (
    <Container className={styles.container}>
      <div className={clsx(loading && styles.containerNotActive)} />
      {!photoData && (
        <StartView
          loading={loading}
          showMenuUpload={showMenuUpload}
          setShowMenuUpload={setShowMenuUpload}
          savePhoto={savePhoto}
          onShowCapturePhoto={onShowCapturePhoto}
        />
      )}

      <CapturePhotoModal savePhoto={savePhoto} onClose={onCloseCapturePhoto} open={isShowCapturePhoto} />
      <ErrorModal open={error} onClose={onCloseModalError} />
    </Container>
  )
}

export default UploadPalmPhoto
