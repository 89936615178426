import { ReactComponent as AstrologyWoman } from 'assets/svg/compatibility/astrology-woman.svg'
import { useEffect } from 'react'
import { analyticEvent } from 'utils/analytics'

import NextButton from 'components/compatibility/next-button'
import ContentWrapper from 'components/content-wrapper'
import Policy from 'components/policy'

import List from './list'
import styles from './styles.module.scss'

export const Welcome = () => {
  useEffect(() => {
    analyticEvent({ name: 'CompatibilityOnboardingStarted' })
  }, [])

  const onNextPage = () => {
    analyticEvent({ name: 'CompatibilityOnboardingIntroClicked' })
  }

  return (
    <ContentWrapper className={styles.wrapper}>
      <div className={styles.title}>
        <h1>Understand yourself and improve your relationships with the help of astrology</h1>
      </div>
      <AstrologyWoman className={styles.womanImg} />
      <List />
      <p className={styles.description}>Answer a few questions so we can match you with your astrologer.</p>
      <NextButton onClick={onNextPage} className={styles.nextButton} title="Let’s Start" />
      <Policy className={styles.welcomePolicy} />
    </ContentWrapper>
  )
}
export default Welcome
