import { useNavigate } from 'react-router-dom'
import { useSelect } from 'store/hook'

import useCurrentIndexRoute from './useCurrentRouteIndex'

const useNextRoute = () => {
  const { currentRoutes } = useSelect()
  const navigate = useNavigate()

  const currentIndexRoute = useCurrentIndexRoute()

  const nextRoute = currentRoutes[currentIndexRoute + 1]

  const onNavigate = (to?: string) => {
    if (currentIndexRoute + 1 !== currentRoutes.length && nextRoute.path) navigate(to ?? nextRoute.path)
  }
  return onNavigate
}
export default useNextRoute
