import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'
import { useSelect } from 'store/hook'

import styles from './styles.module.scss'

const EmailHeader: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const { emailUser } = useSelect()

  return (
    <div className={clsx(styles.emailHeader, className)} {...props}>
      <h1>{emailUser}</h1>
      <div>
        <h1>{emailUser[0]}</h1>
      </div>
    </div>
  )
}

export default EmailHeader
