import dayjs from 'dayjs'
import { ChangeEvent, FC, memo, useState } from 'react'
import { zeroFormat } from 'utils/zeroFormatString'

import Input from 'components/compatibility/input'
import { Option } from 'components/select'

type Props = {
  className?: string
  day?: Option | null
  month?: Option | null
  setDay: (month: Option) => void
  setError: (isError: boolean) => void
}

const currentDate = dayjs()
const currentYear = currentDate.get('year')

const InputDay: FC<Props> = ({ day, month, setDay, setError, className }) => {
  const [errorDay, setErrorDay] = useState(false)

  const onChangeDay = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    const daysInMonth = dayjs(`${currentYear}-${zeroFormat(month?.value as string)}`).daysInMonth()

    const formatDays = !+value ? value : zeroFormat(value)

    if (+formatDays <= daysInMonth) {
      setDay({ value: formatDays, displayValue: formatDays })
    }
  }
  const onBlurDay = () => {
    if (day?.value && +day.value <= 0) {
      setErrorDay(true)
      setError(true)
    }
  }

  const onFocusDay = () => {
    setErrorDay(false)
    setError(false)
  }

  return (
    <div className={className}>
      <h3>Day</h3>
      <Input
        value={day?.value}
        isError={errorDay}
        placeholder="DD"
        onChange={onChangeDay}
        onBlur={onBlurDay}
        onFocus={onFocusDay}
      />
    </div>
  )
}

export default memo(InputDay)
