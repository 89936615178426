import { Elements } from '@stripe/react-stripe-js'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { FC, useEffect, useState } from 'react'

import AppleGooglePayButtons, { AppleGooglePayProps } from './apple-google-pay'

const AppleGooglePay: FC<AppleGooglePayProps> = (props) => {
  const { onReady } = props
  const [stripePromise, setStripePromise] = useState<Stripe | null>()

  useEffect(() => {
    loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`).then((response) => {
      if (response) {
        setStripePromise(response)
      } else {
        onReady()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return stripePromise ? (
    <Elements stripe={stripePromise}>
      <AppleGooglePayButtons {...props} />
    </Elements>
  ) : null
}
export default AppleGooglePay
