import { paths } from 'onboardings/compatibility-onboarding/paths'
import { useDispatch, useSelect } from 'store/hook'
import BornPlaceTemplate from 'templates-page/compatibility/born-place'
import { analyticEvent } from 'utils/analytics'
import { upperCaseFirstLetter } from 'utils/upperCaseFirstLetter'

export const PartnerBornPlace = () => {
  const { partner, variant } = useSelect()
  const dispatch = useDispatch()

  if (!partner || partner === 'not-data') {
    return null
  }

  const onSelectPlace = (bornPlace: string) => {
    dispatch({
      type: 'changePartner',
      payload: { ...partner, bornPlace },
    })
    analyticEvent({
      name: 'CompatibilityOnboardingKnowEverythingPOBPassed',
    })
  }

  const onClear = () => {
    dispatch({
      type: 'changePartner',
      payload: { ...partner, bornPlace: null },
    })
  }

  return (
    <BornPlaceTemplate
      isBackHistory
      to={'bornTime' in partner ? '../partner-born-time' : paths.analyzing}
      title={`What is your ${upperCaseFirstLetter(variant || '')}’s place of birth?`}
      description="The exact birthplace lets us create your unique astrological blueprint."
      place={partner.bornPlace || ''}
      pages={'bornTime' in partner ? 3 : 2}
      currentPage={2}
      onSelectPlace={onSelectPlace}
      onClear={onClear}
    />
  )
}
export default PartnerBornPlace
