import { useTranslation } from 'react-i18next'
import { useDispatch, useSelect } from 'store/hook'
import BirthdayTemplate from 'templates-page/birthday'
import { BirthdayType } from 'types'
import { analyticEvent } from 'utils/analytics'

import Container from 'components/container'

export const Birthday = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { birthday } = useSelect()
  const { year, month, day } = birthday

  const onNextPage = (payload: BirthdayType) => {
    dispatch({
      type: 'changeBirthday',
      payload,
    })
    analyticEvent({ name: 'PalmistryOnboardingDOBClicked', gtagData: `${day?.value}-${month?.value}-${year?.value}` })
  }

  return (
    <Container>
      <BirthdayTemplate
        title={t('birth-day-title')}
        description="Your birth date reveals your core personality traits, needs and desires."
        birthday={birthday}
        onNextPage={onNextPage}
      />
    </Container>
  )
}
