import { boards } from 'config'

export const paths = {
  welcome: `/${boards.compatibility}/welcome`,
  birthday: `/${boards.compatibility}/birthday`,
  bornPlace: `/${boards.compatibility}/born-place`,
  bornTime: `/${boards.compatibility}/born-time`,
  target: `/${boards.compatibility}/target`,
  targetVariant: `/${boards.compatibility}/target-variant`,
  // reviews: `/${boards.compatibility}/reviews`,
  partner: `/${boards.compatibility}/partner`,
  analyzing: `/${boards.compatibility}/analyzing`,
  // report: `/${boards.compatibility}/report`,
  email: `/${boards.compatibility}/email`,
  final: `/${boards.compatibility}/final`,
  paywall: `/${boards.compatibility}/paywall`,
  download: `/${boards.compatibility}/download`,
  discount: `/${boards.compatibility}/discount`,
  premiumBundle: `/${boards.compatibility}/premium-bundle`,
}
