import clsx from 'clsx'
import { useRef } from 'react'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'

import ContentWrapper from 'components/content-wrapper'
import FooterPaywall from 'components/footer-paywall'
import Logos from 'components/logos'
import NextButton from 'components/next-button'
import EmailHeader from 'components/palmistry/email-header'

import Experts from './experts'
import Hands from './hands'
import MoneyBack from './money-back'
import Payment from './payment'
import PersonalPlan from './personal-plan'
import Reviews from './reviews'
import styles from './styles.module.scss'
import Timer from './timer'
import Welcome from './welcome'
import Zodiac from './zodiac'

export const Paywall1 = () => {
  const paymentSectionButtonRef = useRef<HTMLDivElement>(null)
  const nextButtonRef = useRef<HTMLDivElement>(null)
  const isRefsNull = !paymentSectionButtonRef.current || !nextButtonRef.current
  const entryPaymentSectionButton = useIntersectionObserver(paymentSectionButtonRef, { threshold: 0.5 })
  const entryNextButton = useIntersectionObserver(nextButtonRef, { threshold: 0.5 })

  const isVisiblePaymentSectionButton = !!entryPaymentSectionButton?.isIntersecting
  const isVisibleNextButton = !!entryNextButton?.isIntersecting

  const isHideFooter = isVisiblePaymentSectionButton || isVisibleNextButton || isRefsNull

  return (
    <ContentWrapper className={styles.paywallWrapper}>
      <EmailHeader />
      <Welcome />
      <Payment ref={paymentSectionButtonRef} />
      <MoneyBack />
      <Hands />
      <Zodiac />
      <PersonalPlan />
      <section ref={nextButtonRef} className={styles.nextButtonWrapper}>
        <NextButton title="Get personal prediction" />
      </section>
      <Reviews />
      <Experts />
      <Logos className={styles.logos} />
      <FooterPaywall className={styles.footer} />
      <div className={clsx(styles.footerButton, isHideFooter && styles.footerButtonHide)}>
        <Timer /> <NextButton title="Get personal prediction" />
      </div>
    </ContentWrapper>
  )
}
