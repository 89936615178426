import screenshot from 'assets/images/compatibility/screenshot.png'
import screenshotPreview from 'assets/images/preview/screenshot.png'
import { ReactComponent as Arrow } from 'assets/svg/arrow-head.svg'
import { ReactComponent as Headphone } from 'assets/svg/headphone.svg'
import { ReactComponent as Setting } from 'assets/svg/setting.svg'
import { ReactComponent as Star } from 'assets/svg/star-4-point2.svg'
import { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { analyticEvent } from 'utils/analytics'

import ButtonBack from 'components/button-back'
import NextButton from 'components/compatibility/next-button'
import ContentWrapper from 'components/content-wrapper'
import EmailHeader from 'components/email-header'
import Logos from 'components/logos'

import Review from './review'
import styles from './styles.module.scss'

const items = [
  'Get personalized compatibility readings with an unlimited number of people',
  'Get daily horoscopes with personalized practical guidance',
  'Supercharge your relationships and gain new perspectives',
  'Attract your crush’s attention',
  'Improve relationships with colleagues',
  'Build great friendships',
  'And more!',
]

const reviews = [
  {
    name: 'Isaiah tommy',
    review: (
      <p>
        Precise needles, answers and explanations some thoughts about myself and often told me I was struggling with
        myself. And totally TRUE! Mindblowing but this app helps me throw more and more throws.
      </p>
    ),
  },
  {
    name: 'Mamafrauts',
    review: (
      <p>
        <b>the best</b> Vladana is the BEST astrologer I’ve gotten reading from! She is very thorough and gives detailed
        info based on what questions you ask her... highly recommend!!! :)
      </p>
    ),
  },
  {
    name: 'HappyFeeetz',
    review: (
      <p>
        <b>Great app</b> My astrologer guide is pretty communicative and informative. I do not know much about astrology
        so she helped me understand some concepts.
      </p>
    ),
  },
]

export const Final: FC = () => {
  const onNextPage = () => {
    analyticEvent({
      name: 'CompatibilityOnboardingPlanExplanationPassed',
    })
  }

  return (
    <ContentWrapper className={styles.finalWrapper}>
      <EmailHeader />
      <ButtonBack className={styles.buttonBack}>
        <div className={styles.buttonContent}>
          <Arrow />
          Back
        </div>
      </ButtonBack>
      <h1 className={styles.title}>We’ve prepared your personalized compatibility reading.</h1>
      <h4 className={styles.secondTitle}>
        Our astrologers have also identified 8 ways to improve your relationship today!
      </h4>
      <div className={styles.descriptionBlock}>
        <h1>We delve deeper than any other app and you will:</h1>
        <ul>
          {items.map((item) => (
            <li key={item}>
              <Star />
              <p>{item}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.imageWrapper}>
        <LazyLoadImage
          src={screenshot}
          width={375}
          height={350}
          alt="Screenshot App"
          placeholderSrc={screenshotPreview}
        />
      </div>

      <div className={styles.reviewsSection}>
        <h1>Join thousands of happy users</h1>
        <ul>
          {reviews.map((item, index) => (
            <Review name={item.name} review={item.review} key={index} />
          ))}
        </ul>
      </div>
      <Logos className={styles.customLogos} />
      <NextButton onClick={onNextPage} className={styles.customNextButton} />
      <footer>
        <h4>Questions? We’re here to help.</h4>
        <div className={styles.links}>
          <a href="https://help.hint.app/en/articles/5783844-get-help-from-hint-support">
            <Headphone /> <h3>Customer Support</h3>
          </a>
          <a href=" https://help.hint.app/en/">
            <Setting /> <h3>Help Center</h3>
          </a>
        </div>
        <p>
          © 2022, RLabs America Inc DBA Hint
          <br />
          112 S.French Street, Suite 105, Wilmington, DE 19801, US
        </p>
      </footer>
    </ContentWrapper>
  )
}

export default Final
