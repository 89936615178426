import clsx from 'clsx'
import { FC } from 'react'

import styles from './styles.module.scss'

type Props = {
  className?: string
}
const PalmPhotoWrapper: FC<Props> = ({ className }) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.corners}>
        <div className={styles.lightBlueCircle} />
        <svg
          version="1.1"
          id="L3"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 220 220"
          enableBackground="new 0 0 0 0"
        >
          <circle fill="none" stroke="#EFF2FD" strokeWidth="2" cx="110" cy="110" r="105" />
          <circle fill="#066FDE" stroke="none" strokeWidth="3" cx="110" cy="215" r="4">
            <animateTransform
              attributeName="transform"
              dur="15s"
              type="rotate"
              from="0 110 110"
              to="360 110 110"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      </div>
    </div>
  )
}

export default PalmPhotoWrapper
