import { ReactComponent as Aquarius } from 'assets/svg/compatibility/aquarius.svg'
import { ReactComponent as Aries } from 'assets/svg/compatibility/aries.svg'
import { ReactComponent as Cancer } from 'assets/svg/compatibility/cancer.svg'
import { ReactComponent as Capricorn } from 'assets/svg/compatibility/capricorn.svg'
import { ReactComponent as Gemini } from 'assets/svg/compatibility/gemini.svg'
import { ReactComponent as Leo } from 'assets/svg/compatibility/leo.svg'
import { ReactComponent as Libra } from 'assets/svg/compatibility/libra.svg'
import { ReactComponent as Pisces } from 'assets/svg/compatibility/pisces.svg'
import { ReactComponent as Sagittarius } from 'assets/svg/compatibility/sagittarius.svg'
import { ReactComponent as Scorpio } from 'assets/svg/compatibility/scorpio.svg'
import { ReactComponent as Taurus } from 'assets/svg/compatibility/taurus.svg'
import { ReactComponent as Virgo } from 'assets/svg/compatibility/virgo.svg'
import clsx from 'clsx'
import { FC } from 'react'
import { useDispatch, useSelect } from 'store/hook'
import { Zodiac as ZodiacType } from 'types'
import { analyticEvent } from 'utils/analytics'
import useNextRoute from 'utils/hooks/useNextRoute'

import ProgressBar from 'components/compatibility/progress-bar'
import ContentWrapper from 'components/content-wrapper'

import styles from './styles.module.scss'

const zodiacs = [
  { title: 'aries', img: <Aries /> },
  { title: 'taurus', img: <Taurus /> },
  { title: 'gemini', img: <Gemini /> },
  { title: 'cancer', img: <Cancer /> },
  { title: 'leo', img: <Leo /> },
  { title: 'virgo', img: <Virgo /> },
  { title: 'libra', img: <Libra /> },
  { title: 'scorpio', img: <Scorpio /> },
  { title: 'sagittarius', img: <Sagittarius /> },
  { title: 'capricorn', img: <Capricorn /> },
  { title: 'aquarius', img: <Aquarius /> },
  { title: 'pisces', img: <Pisces /> },
]

export const Zodiac: FC = () => {
  const dispatch = useDispatch()
  const { partner } = useSelect()
  const nextRoute = useNextRoute()

  const currentZodiac = () => {
    if (typeof partner === 'object') {
      return partner?.zodiac
    }
    return false
  }

  const onClick = (zodiac: ZodiacType) => {
    dispatch({
      type: 'changePartner',
      payload: { zodiac },
    })
    analyticEvent({
      name: 'CompatibilityOnboardingZodiacSignZodiacPassed',
    })
    nextRoute()
  }

  return (
    <ContentWrapper>
      <ProgressBar isBackHistory pages={1} currentPage={1} />
      <p className={styles.title}>Which sign do you want to check compatibility with?</p>
      <div className={styles.zodiacs}>
        {zodiacs.map((zodiac) => (
          <button
            onClick={() => onClick(zodiac.title as ZodiacType)}
            className={clsx(styles.zodiacButton, currentZodiac() === zodiac.title && styles.activeButton)}
            key={zodiac.title}
          >
            <div className={styles.imgWrapper}>{zodiac.img}</div>
            <h3>{zodiac.title}</h3>
          </button>
        ))}
      </div>
    </ContentWrapper>
  )
}
export default Zodiac
