import { ReactComponent as CameraIcon } from 'assets/svg/camera.svg'
import { ReactComponent as Folder } from 'assets/svg/folder.svg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ImageUploading, { ImageListType } from 'react-images-uploading'

import styles from './styles.module.scss'

type Props = {
  onShowCapturePhoto: () => void
  savePhoto: (imageUrl: string, typeUpload: string) => void
}
const UploadModal: FC<Props> = ({ onShowCapturePhoto, savePhoto }) => {
  const { t } = useTranslation()

  const onChange = (imageList: ImageListType) => {
    const imageBase64 = imageList[0].dataURL?.split(',')[1]

    savePhoto(imageBase64 as string, 'Choose File')
  }

  return (
    <ul className={styles.menu}>
      <li>
        <div onClick={onShowCapturePhoto}>
          {t('take-photo')} <CameraIcon />
        </div>
      </li>
      <li>
        <ImageUploading onChange={onChange} value={[]}>
          {({ onImageUpload }) => (
            <div onClick={onImageUpload}>
              {t('choose-file')} <Folder />
            </div>
          )}
        </ImageUploading>
      </li>
    </ul>
  )
}

export default UploadModal
