import LizHoffmanImg from 'assets/avatars/liz-hoffman.png'
import MarieMichelleRicciImg from 'assets/avatars/marie-michelle-ricci.png'
import ShaunaTaylorImg from 'assets/avatars/shauna-taylor.png'
import { FC } from 'react'

import Review, { UserReview } from './review'
import styles from './styles.module.scss'

const userReviews: Array<UserReview> = [
  {
    avatar: LizHoffmanImg,
    name: 'Liz Hoffman',
    review:
      'Just wow! Palmistry has shown me my future and now I know I need to work hard and not give up! This changed my life! ',
  },
  {
    avatar: MarieMichelleRicciImg,
    name: 'Marie Michelle Ricci',
    review:
      'This prediction is so accurate! I never knew my palm can show the time I meet my soul-mate and how many kids I am going to have. This is spectacular!',
  },
  {
    avatar: ShaunaTaylorImg,
    name: 'Shauna Taylor',
    review: 'I was pretty shocked at how accurrate all the stuff about my husband was.  ',
  },
]

const Reviews: FC = () => {
  return (
    <section className={styles.reviews}>
      <h1>
        Here are a few stories from <br /> Hint users
      </h1>
      {userReviews.map((item, index) => (
        <Review userReview={item} key={index} />
      ))}
    </section>
  )
}

export default Reviews
