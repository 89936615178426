import clsx from 'clsx'
import { FC } from 'react'

import CommonInput, { InputProps } from 'components/input'

import styles from './styles.module.scss'

const Input: FC<InputProps> = ({ className, isError, ...props }) => {
  return <CommonInput className={clsx(styles.customInput, className, isError && styles.customInputError)} {...props} />
}

export default Input
