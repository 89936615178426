import LizHoffmanImg from 'assets/avatars/liz-hoffman.png'
import MarieMichelleRicciImg from 'assets/avatars/marie-michelle-ricci.png'
import ShaunaTaylorImg from 'assets/avatars/shauna-taylor.png'
import clsx from 'clsx'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'translations/i18n'

import Review, { UserReview } from 'components/review'

import styles from './styles.module.scss'

const userReviews: Array<UserReview> = [
  {
    avatar: LizHoffmanImg,
    name: 'Liz Hoffman',
    review: i18n.t('palm-paywall-reviews-liz-hoffman'),
  },
  {
    avatar: MarieMichelleRicciImg,
    name: 'Marie Michelle Ricci',
    review: i18n.t('palm-paywall-reviews-marie-michelle-ricci'),
  },
  {
    avatar: ShaunaTaylorImg,
    name: 'Shauna Taylor',
    review: i18n.t('palm-paywall-reviews-shauna-taylor'),
  },
]

const Reviews: FC = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div className={styles.title}>
        <h2>{t('palm-paywall-reviews-title')}</h2>
      </div>
      {userReviews.map((item, index) => (
        <Review className={clsx(!index && styles.firstReview)} userReview={item} key={index} />
      ))}
    </div>
  )
}

export default Reviews
