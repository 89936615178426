import { ReactComponent as Star } from 'assets/svg/star2.svg'
import { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

type Props = {
  name: string
  review: ReactNode
}

const Review: FC<Props> = ({ name, review }) => {
  return (
    <div className={styles.review}>
      <div className={styles.header}>
        <div className={styles.avatar}>{name[0]}</div>
        <div>
          <h3>{name}</h3>
          {Array.from({ length: 5 }, (_, index) => (
            <Star key={index} />
          ))}
        </div>
      </div>
      <div>{review}</div>
    </div>
  )
}

export default Review
