import { ReactComponent as Star } from 'assets/svg/star2.svg'
import { ReactComponent as Tooltip } from 'assets/svg/tooltip.svg'
import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'

import styles from './styles.module.scss'

export type UserReview = {
  avatar: string
  name: string
  review: string
}
type Props = {
  userReview: UserReview
} & HTMLAttributes<HTMLElement>

const Review: FC<Props> = ({ userReview, className }) => {
  const { avatar, name, review } = userReview
  return (
    <section className={clsx(styles.wrapper, className)}>
      <header>
        <img className={styles.imageAvatar} src={avatar} alt="User avatar" />
        <div className={styles.wrapperUserName}>
          <div className={styles.nameBlock}>
            <h1>{name}</h1>
            <Tooltip />
          </div>
          <div>
            {Array.from({ length: 5 }, (_, index) => (
              <Star key={index} />
            ))}
          </div>
        </div>
      </header>
      <p className={styles.review}>{review}</p>
    </section>
  )
}

export default Review
