import clsx from 'clsx'
import { eulaLink, privacyLink, supportLink } from 'config'
import { FC } from 'react'
import { Trans } from 'react-i18next'

import styles from './styles.module.scss'

type Props = { className?: string }

const Policy: FC<Props> = ({ className }) => {
  const eulaLinkComponent = (
    <a href={eulaLink} target="_blank" rel="noreferrer nofollow">
      EULA
    </a>
  )

  const privacyLinkComponent = (
    <a href={privacyLink} target="_blank" rel="noreferrer nofollow">
      Privacy Notice
    </a>
  )
  const supportLinkComponent = (
    <a href={supportLink} target="_blank" rel="noreferrer nofollow">
      Support
    </a>
  )

  return (
    <div className={clsx(styles.policy, className)}>
      <p>
        <Trans i18nKey="policy-text" components={[eulaLinkComponent, privacyLinkComponent, supportLinkComponent]} />
      </p>
    </div>
  )
}

export default Policy
