/* eslint-disable prettier/prettier */
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import ReactDOM from 'react-dom/client'
import 'styles/main.scss'

import './index.css'

import App from './app'
import reportWebVitals from './reportWebVitals'
import './translations/i18n'

dayjs.extend(localeData)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
