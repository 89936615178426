import { useNavigate } from 'react-router-dom'
import { useSelect } from 'store/hook'

import useCurrentIndexRoute from './useCurrentRouteIndex'

const useBackRoute = () => {
  const { currentRoutes } = useSelect()
  const navigate = useNavigate()

  const currentIndexRoute = useCurrentIndexRoute()

  const nextRoute = currentRoutes[currentIndexRoute - 1]

  const onNavigate = () => {
    if (currentIndexRoute >= 1 && nextRoute.path) navigate(nextRoute.path)
  }
  return onNavigate
}
export default useBackRoute
