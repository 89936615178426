import { useEffect, useState } from 'react'

const easeProgress = (t: number) => t * t * (3 - 2 * t)

type Props = {
  started: boolean
  progressDuration: number
  onComplete?: () => void
}

const useAnimatedProgressValue = ({ started, progressDuration, onComplete }: Props) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (started) {
      const startTime = Date.now()
      let animationFrame: ReturnType<typeof requestAnimationFrame>
      const handleAnimationFrame = () => {
        const t = Math.min((Date.now() - startTime) / progressDuration, 1)
        setProgress(easeProgress(t))
        if (t === 1 && onComplete) {
          onComplete()
        } else {
          animationFrame = requestAnimationFrame(handleAnimationFrame)
        }
      }
      animationFrame = requestAnimationFrame(handleAnimationFrame)
      return () => cancelAnimationFrame(animationFrame)
    }
    return undefined
  }, [started, progressDuration, onComplete, setProgress])

  return progress
}
export default useAnimatedProgressValue
