import { ReactComponent as Arrow } from 'assets/svg/arrow-head.svg'
import clsx from 'clsx'
import { FC, memo, useMemo } from 'react'
import { useSelect } from 'store/hook'
import useCurrentIndexRoute from 'utils/hooks/useCurrentRouteIndex'

import ButtonBack from 'components/button-back'

import styles from './styles.module.scss'

type Props = {
  pages?: number
  currentPage?: number
  isBackHistory?: boolean
}

const ProgressBar: FC<Props> = ({ isBackHistory, pages, currentPage }) => {
  const { currentRoutes } = useSelect()
  const currentIndexRoute = useCurrentIndexRoute()

  const routesCount = currentRoutes.length - 1

  const selectPage = currentPage || currentIndexRoute - 1

  const allPages = pages || routesCount

  const progressPercent = (selectPage * 100) / allPages
  const isFifty = progressPercent >= 50

  const progressWidth = useMemo(() => ({ width: `${progressPercent}%` }), [progressPercent])

  return (
    <div className={styles.progressBarWrapper}>
      <ButtonBack isBackHistory={isBackHistory} className={styles.buttonBack}>
        <div className={styles.buttonContent}>
          <Arrow /> Back
        </div>
      </ButtonBack>
      <h3 className={styles.currentRoute}>{`${selectPage}/${allPages}`}</h3>
      <div className={clsx(styles.progressBar, isFifty && styles.active)}>
        <div className={styles.progress} style={progressWidth} />
      </div>
    </div>
  )
}

export default memo(ProgressBar)
