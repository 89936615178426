import { FC, useState } from 'react'
import useNextRoute from 'utils/hooks/useNextRoute'
import useUpgradePlan from 'utils/hooks/useUpgradePlan'

import Spinner from 'components/circle-spinner'
import ContentWrapper from 'components/content-wrapper'
import EmailHeader from 'components/email-header'

import styles from './styles.module.scss'

const COUPON_ID = 'UPGRADEPLAN-33'

const Discount: FC = () => {
  const nextRoute = useNextRoute()
  const upgradePlan = useUpgradePlan()

  const [isLoading, setIsLoading] = useState(false)

  const onUpgradePlan = async () => {
    setIsLoading(true)
    await upgradePlan(COUPON_ID)
      .then(() => {
        nextRoute()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <ContentWrapper className={styles.discountWrapper}>
      <EmailHeader />

      <h1 className={styles.title}>Not planning on looking back?</h1>

      <div className={styles.blocksWrapper}>
        <section className={styles.leftBlock}>
          <h1>
            $19 for <br /> 1-week plan
          </h1>
          <p>Total savings</p>
          <h1>$0</h1>
          <p>7-day trial</p>
          <h1>yes</h1>
          <button disabled={isLoading} onClick={() => nextRoute()}>
            Start trial
          </button>
        </section>
        <section className={styles.rightBlock}>
          <header className={styles.headerBlock}>save 33%</header>
          <h1>
            $12.73 for <br /> 1-week plan
          </h1>
          <p>Total savings</p>
          <h1>$6.27</h1>
          <p>3-day trial</p>
          <h1>no</h1>
          <button disabled={isLoading} onClick={onUpgradePlan}>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                Pay now and <br /> skip trial
              </>
            )}
          </button>
        </section>
      </div>
    </ContentWrapper>
  )
}
export default Discount
