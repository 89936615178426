/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as Check } from 'assets/svg/check.svg'
import { ReactComponent as Stars } from 'assets/svg/compatibility/stars.svg'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useState } from 'react'
import { analyticEvent } from 'utils/analytics'
import useNextRoute from 'utils/hooks/useNextRoute'

import CircleAnimatedProgress from 'components/circle-animated-progress'
import ContentWrapper from 'components/content-wrapper'

import styles from './styles.module.scss'

const items = [
  'Processing your answers and calculating your unique astrological profile',
  'Analyzing your compatibility',
  'Preparing to connect you with your astrologer',
]

const countElements = items.length

const SHOW_ITEM_DURATION = 2000
const DELAY_ITEM = 100

export const Analyzing: FC = () => {
  const nextRoute = useNextRoute()

  const [currentIndexElement, setCurrentIndexElement] = useState(0)

  useEffect(() => {
    if (currentIndexElement < countElements) {
      const timeoutId = setTimeout(() => {
        setCurrentIndexElement((previous) => previous + 1)
      }, SHOW_ITEM_DURATION - DELAY_ITEM)
      return () => {
        clearTimeout(timeoutId)
      }
    }
    // eslint-disable-next-line consistent-return
    return undefined
  }, [currentIndexElement])

  const nextStep = useCallback(() => {
    analyticEvent({
      name: 'CompatibilityOnboardingLoaderPassed',
    })
    nextRoute()
  }, [])

  return (
    <ContentWrapper className={styles.analyzingWrapper}>
      <div className={styles.starsWrapper}>
        <Stars />
        <div className={styles.animate}>
          <CircleAnimatedProgress onComplete={nextStep} progressDuration={SHOW_ITEM_DURATION * countElements} />
        </div>
      </div>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <li key={item}>
            <Check className={clsx(styles.iconCheck, currentIndexElement >= index && styles.iconCheckShow)} />
            <h3>{item}</h3>
          </li>
        ))}
      </ul>
    </ContentWrapper>
  )
}
export default Analyzing
