let lastId = 0

export const uniqueId = (prefix = 'id') => {
  lastId++
  return `${prefix}${lastId}`
}

export const isDesktop = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    return false
  }
  return true
}

export const isLocalhost = () => {
  const url = window.location.href

  return url.includes('localhost') || url.includes('127.0.0.1')
}

export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const isAppleSafari = () => {
  const appleExpression = /Apple/i
  const safariExpression = /Safari/i
  return appleExpression.test(navigator.vendor) && safariExpression.test(navigator.userAgent)
}

export const isString = (val: unknown) => typeof val === 'string'

/* eslint-disable */
export const uuidv4 = () => {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  )
}
/* eslint-enable */

export const sha256 = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => `00${b.toString(16)}`.slice(-2)).join('')

  return hashHex
}

export const getCookie = (name: string) => {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(`${name}=`)
    if (start !== -1) {
      start = start + name.length + 1
      let end = document.cookie.indexOf(';', start)
      if (end === -1) {
        end = document.cookie.length
      }
      return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}
