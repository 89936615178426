import { ReactComponent as Hand1 } from 'assets/svg/palmistry/palmistry1.svg'
import { ReactComponent as Hand2 } from 'assets/svg/palmistry/palmistry2.svg'
import { ReactComponent as Hand3 } from 'assets/svg/palmistry/palmistry3.svg'
import { ReactComponent as Hand4 } from 'assets/svg/palmistry/palmistry4.svg'
import { ReactComponent as Hand5 } from 'assets/svg/palmistry/palmistry5.svg'
import { FC } from 'react'

import styles from './styles.module.scss'

const hands = [
  {
    img: <Hand1 />,
    text: [
      <p>
        <em>Love line</em> shows your attitude to love and the quality of love
      </p>,
      <p>A long thumb indicates good fortune</p>,
    ],
  },
  {
    img: <Hand2 />,
    text: [
      <p>
        <em>Head line</em> reflects your intelligence and mentality
      </p>,
      <p>A long index finger indicates a natural leader</p>,
    ],
  },
  {
    img: <Hand3 />,
    text: [
      <p>
        <em>Life line</em> defines the quality of your life and what you will achieve
      </p>,
      <p> A short middle finger reveals a free spirit</p>,
    ],
  },
  {
    img: <Hand4 />,
    text: [
      <p>
        <em>Fate line</em> represents your material achievement and career goals
      </p>,
      <p>A long ring finger reveals that a person tends to take risk</p>,
    ],
  },
  {
    img: <Hand5 />,
    text: [<p>A short little finger indicates the person’s lack of self-confidence</p>],
  },
]

const Hands: FC = () => {
  return (
    <section className={styles.hands}>
      <h1>What do your hands and fingers say about you?</h1>
      <ul className={styles.handsList}>
        {hands.map((hand, index) => (
          <li key={index} className={styles.handsItem}>
            {hand.img}
            <ul className={styles.textList}>
              {hand.text.map((item, indexText) => (
                <li className={styles.text} key={indexText}>
                  {item}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Hands
