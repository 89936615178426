import { FC, useCallback, useRef } from 'react'
import Webcam from 'react-webcam'
import { isDesktop } from 'utils/helpers'

import Modal, { ModalProps } from 'components/modal'

import styles from './styles.module.scss'

type Props = {
  savePhoto: (image: string, typeUpload: string) => void
} & Omit<ModalProps, 'children'>

const videoConstraints = {
  facingMode: isDesktop() ? 'user' : { exact: 'environment' },
}

const CapturePhotoModal: FC<Props> = ({ savePhoto, open, className, onClose }) => {
  const webcamRef = useRef<Webcam | null>(null)

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()

      savePhoto(imageSrc?.split(',')[1] as string, 'Take photo')
      if (onClose) {
        onClose()
      }
    }
  }, [savePhoto, onClose])

  return (
    <Modal open={open} className={className} onClose={onClose}>
      <div className={styles.wrapperCamera}>
        <Webcam audio={false} ref={webcamRef} videoConstraints={videoConstraints} screenshotFormat="image/jpeg" />
        <div className={styles.buttonShutter} onClick={capture} />
      </div>
    </Modal>
  )
}

export default CapturePhotoModal
